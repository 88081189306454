import { IProductInCart } from 'app/shared/model/product-in-cart.model';
import { IPaymentMethod } from 'app/shared/model/payment-method.model';
import { IOrder } from 'app/shared/model/order.model';
import { ICart } from 'app/shared/model/cart.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';

export interface IPayment {
  id?: number;
  paymentAmount?: number | null;
  referenceCode?: string | null;
  tax?: number | null;
  shippingCharge?: number | null;
  status?: PaymentStatus | null;
  productInCarts?: IProductInCart[] | null;
  paymentMethod?: IPaymentMethod | null;
  order?: IOrder | null;
  cart?: ICart | null;
}

export const defaultValue: Readonly<IPayment> = {};
