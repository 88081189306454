import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductInCart from './product-in-cart';
import ProductInCartDetail from './product-in-cart-detail';
import ProductInCartUpdate from './product-in-cart-update';
import ProductInCartDeleteDialog from './product-in-cart-delete-dialog';

const ProductInCartRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductInCart />} />
    <Route path="new" element={<ProductInCartUpdate />} />
    <Route path=":id">
      <Route index element={<ProductInCartDetail />} />
      <Route path="edit" element={<ProductInCartUpdate />} />
      <Route path="delete" element={<ProductInCartDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductInCartRoutes;
