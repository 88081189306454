import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Nav, NavItem, NavLink, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import OrderActiveView from './order-active-view';
import OrderShippedView from './order-shipped-view';
import OrderCancelledView from './order-cancelled-view';
import { getEntities as getAddressEntities } from '../address/address.reducer';
import { getEntities as getOrderEntities } from './order.reducer';
import { isArray } from 'lodash';

export const Order = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [showTab, setShowTab] = useState('Active');
  const [searchItem, setSearchItem] = useState('');
  const [searchTypeAddress, setSearchTypeAddress] = useState(false);
  const [loader, setLoader] = useState(false);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handleSearchBar = async e => {
    const searchTerm = e.target.value;
    console.log(searchTerm);
    setSearchItem(searchTerm);
  };

  // order search reducer
  const orderSearch = async q => {
    try {
      let query = q;
      if (!isArray(query)) {
        query = [query];
      }
      let resp: any = await dispatch(
        getOrderEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `id.in=${query}`,
        })
      );
      setLoader(false);
      return resp.payload.data;
    } catch (error) {}
  };
  // order search Address
  const searchAddress = async (query, field = 'firstName') => {
    try {
      let resp: any = await dispatch(
        getAddressEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: `${field}.equals=${query.trim()}`,
        })
      );
      setLoader(false);
      return resp.payload.data;
    } catch (error) {}
  };
  const submitSearch = async () => {
    setLoader(true);
    let ordersAddressResult = [];
    let searchTerm = searchItem;
    // if(!!Number(searchTerm)){
    let ordersEarchResult = await orderSearch(searchTerm);
    if (ordersEarchResult && ordersEarchResult.length > 0) {
      return ordersEarchResult;
    }
    //}
    else {
      ordersAddressResult = await searchAddress(searchTerm);
      if (ordersAddressResult.length == 0) {
        ordersAddressResult = await searchAddress(searchTerm, 'lastName');
        if (ordersAddressResult.length == 0) {
          ordersAddressResult = await searchAddress(searchTerm, 'email');
        }
        if (ordersAddressResult.length == 0) {
          ordersAddressResult = await searchAddress(searchTerm, 'contact');
        }
      }
    }
    let oids_arr = ordersAddressResult.map(addressObj => addressObj?.order?.id);
    return await orderSearch(oids_arr);
  };

  const handleKeyPress = async e => {
    if (e.key === 'Enter') {
      console.log(e, 'eeeeee');
      await submitSearch();
    }
  };

  return (
    <div className="order-list-view">
      <h2 id="order-heading" data-cy="OrderHeading" className="fs-bold pb-5">
        Orders
      </h2>
      <div className="search_design">
        <input
          type="text"
          //value={searchItem}
          onChange={handleSearchBar}
          placeholder="Type to search"
          onKeyDown={handleKeyPress}
        />
        <button type="submit" onClick={submitSearch} className="primary btn-primary">
          Search
        </button>
      </div>
      {!!searchItem ? (
        <OrderActiveView />
      ) : (
        <div className="order-tabs">
          <Nav tabs className="mb-3 nav-justified">
            <NavItem>
              <NavLink onClick={() => setShowTab('Active')} active={showTab == 'Active'}>
                Active
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => setShowTab('Cancelled')} active={showTab == 'Cancelled'}>
                Cancelled
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={() => setShowTab('Shipped')} active={showTab == 'Shipped'}>
                Shipped
              </NavLink>
            </NavItem>
          </Nav>
          {showTab == 'Active' && <OrderActiveView />}
          {showTab == 'Cancelled' && <OrderCancelledView />}
          {showTab == 'Shipped' && <OrderShippedView />}
        </div>
      )}
    </div>
  );
};

export default Order;
