export interface IOrdersReport {
  id?: number | null;
  orderNumber?: string | null;
  status?: string | null;
  totalAmount?: number | null;
  date?: string | null;
  email?: string | null;
  type?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  street?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
}

export const defaultValue: Readonly<IOrdersReport> = {};
