import { getEntities } from 'app/entities/product/product.reducer';
import { getEntities as getProductInCart, deleteEntity } from 'app/entities/product-in-cart/product-in-cart.reducer';

import { createEntity } from 'app/override/modules/home-page/product.reducer';
import { Storage } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './theme.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link as ScrollLink, Element } from 'react-scroll';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { Checkout } from '../../../modules/checkout/checkout';
import { usePostHog } from 'posthog-js/react';

interface Props {}
export const ProductListView = (props: Props) => {
  const posthog = usePostHog();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const [visible, setVisible] = useState(false);
  const isNew = id === undefined;
  const dataList = useAppSelector(state => state.product.entities);
  const updateProductInCart = useAppSelector(state => state.addProduct.updating);
  const [showLoader, setShowLoader] = useState(false);
  const [posthogFlag, setPosthogFlag] = useState('');
  const [cartId, setCartId] = useState('');
  const [hideItems, setHideItems] = useState(false);
  const [atcCountCheck, setAtcCountCheck] = useState(0);

  let headerCopy = (
    <h1 className="banner-heading fs-lg  text-md-start ">
      Floss <span className="fw-bolder">In Seconds</span>
    </h1>
  );
  let subHeaderCopy = 'Miracle Smile®  Advanced Clean is the revolutionary water flosser with a patent pending H-shaped head';
  if (posthog.getFeatureFlag('homepage_atc') === 'test') {
    if (posthogFlag != 'test') {
      setPosthogFlag('test');
    }
    headerCopy = (
      <h1 className=" banner-heading fs-lg  text-md-start " style={{ fontSize: '3rem' }}>
        Floss <span className="fw-bolder">In Just 10 Seconds</span> With
        <br />
        Miracle Smile® Advanced Clean
      </h1>
    );
    subHeaderCopy = 'The first and only water flosser with a unique H-shaped head';
  }
  if (posthog.getFeatureFlag('homepage_checkout') === 'checkout') {
    if (hideItems == false) {
      setHideItems(true);
    }
  }
  if (posthog.getFeatureFlag('homepage_checkout') === 'control') {
    if (hideItems == true) {
      setHideItems(false);
    }
  }

  const faqs = [
    {
      question: 'How does it work?',
      answer:
        'Miracle Smile® Advanced Clean helps rinse and massage your mouth for a brighter looking and cleaner feeling mouth, right at home! Simply fill the reservoir with water and place the H-shaped head around your top and bottom teeth at the same time, turn it on and glide the flosser across your teeth for a cleaner feeling mouth in seconds.',
    },
    {
      question: 'How often should the heads be replaced?',
      answer: 'It is recommended that you change heads at least every 6 months, depending on use.',
    },
    {
      question: 'Is it water resistant?',
      answer: 'Miracle Smile® Advanced Clean is water resistant. So, it can be used in any part of your daily routine, even in the shower.',
    },
    {
      question: 'How is the tank filled?',
      answer:
        'Simply open the latch for the water compartment and fill directly from the tap, that’s it!  Make sure the latch is securely closed before holding the unit upright.',
    },
    {
      question: "What is the water tank's capacity?",
      answer: "The Miracle Smile® Advanced Clean water flosser's tank can hold up to 220 mL.",
    },
  ];

  const getImageUrl = imageObj => {
    if (imageObj.path != '') {
      return imageObj.path;
    } else {
      return `content/images/${imageObj.name}`;
    }
  };

  useEffect(() => {
    dispatch(getEntities({ query: `productCategoryId.equals=1` }));
    if (window) {
      if (window.screen.width < 767) {
        if (location.pathname == '/') {
          window.onscroll = function () {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled > 100) {
              setVisible(true);
            } else {
              setVisible(false);
            }
          };
        }
      }
    }

    Storage.session.remove('productId');
  }, [id]);

  const addToCart = async productId => {
    setShowLoader(true);
    Storage.session.set('productId', productId);
    const productDetails = dataList.find(product => product.id === productId);
    const addProductDetails = {
      productId: productDetails.id,
      quantity: 1,
      discountCodes: productDetails.discountCodes || null,
    };

    if (localStorage.getItem('cartId') && localStorage.getItem('cartId') != 'null') {
      let cartData = await dispatch(getProductInCart({ query: `cartId.equals=${localStorage.getItem('cartId')}` }));
      let productInCart = cartData.payload['data'];
      // let checkProduct = productInCart.filter(item =>item.product.id != productId && item.product.productCategory.name == 'homepage');
      // console.log('productInCart', productInCart);
      if (productInCart.length > 0) {
        for (let i = 0; i < productInCart.length; i++) {
          await dispatch(deleteEntity(productInCart[i].id));
          // console.log('removeProductInCart', removeProductInCart);
        }
      }
    }

    const { payload } = await dispatch(createEntity(addProductDetails));
    if (payload['status']) {
      ReactGA.gtag('event', 'conversion', {
        send_to: 'AW-11286793753/R4_HCLrtgesYEJmc-4Uq',
      });
      ReactGA.event({
        category: productDetails.name,
        action: 'add to cart',
        value: productDetails.price,
        transport: 'xhr',
      });
      ReactPixel.track('AddToCart', { content_name: productDetails.name, value: `${productDetails.price}`, currency: 'USD' });

      const cartId = payload['data'];
      setCartId(cartId.id);
      setAtcCountCheck(atcCountCheck + 1);
      console.log(atcCountCheck);
      localStorage.setItem('cartId', cartId.id);
      setShowLoader(false);
      if (hideItems) {
        const anchor = document.querySelector('#shipping');
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        navigate('/checkout');
      }
    }
  };

  useEffect(() => {
    let cartId = localStorage.getItem('cartId');
    localStorage.clear();
    localStorage.setItem('cartId', cartId);
    ReactPixel.init('1729701367468489');
    ReactPixel.pageView();
    ReactGA.initialize([{ trackingId: 'G-9GECVX94CM' }, { trackingId: 'AW-11286793753' }]);
    let items = document.querySelectorAll('.faq-main .faq-item');
    items.forEach(function (t) {
      t.addEventListener('click', function (e) {
        items.forEach(function (e) {
          e !== t || e.classList.contains('faq-item-show') ? e.classList.remove('faq-item-show') : e.classList.add('faq-item-show');
        });
      });
    });
  }, []);

  let settings = {
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          dot: true,

          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      {showLoader && (
        <div className="loader">
          <img src="content/images/loader.gif" />
        </div>
      )}
      <ScrollLink
        activeClass="active"
        className="order-fix-btn btn btn-success fw-bolder header-btn"
        to="order"
        spy={true}
        smooth={true}
        duration={700}
        style={{ display: visible ? 'block' : 'none' }}
      >
        ORDER NOW
      </ScrollLink>

      <section className="banner" id="banner">
        <div className="container">
          <div className="row banner-row">
            <div className="col-md-8 d-flex flex-column mt-md-4 pt-5 pb-3 pb-sm-4 py-md-5">
              {headerCopy}
              <p className="banner-paragraph fs-lg text-md-start fw-bolder">{subHeaderCopy}</p>
              <ul className=" text-md-start banner-list">
                <li>4 jets for 360* cleaning</li>
                <li>Multiple Stream and Pulse levels</li>
                <li>Handheld and cordless</li>
              </ul>
              <ScrollLink activeClass="active" className="btn btn-primary content-btn" to="order" spy={true} smooth={true} duration={700}>
                Order Now - Up To 30% Off
              </ScrollLink>
            </div>
          </div>
        </div>
      </section>

      <section id="counter-section" className="counter-section ">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-auto d-md-flex align-items-md-stretch ">
              <div className="count-box py-3 py-lg-5 d-flex gap-4  justify-content-lg-center">
                <img className="d-none d-md-block" src="content/images/counter1.png" />
                <img className="d-block d-md-none" src="content/images/counter1-mobile-view.png" alt="mobileView" />
                <div className="about-numbers text-white fw-bolder fs-3 d-md-block d-none">
                  <span className="purecounter">60 Day</span>
                  <p className="mb-0">Guarantee</p>
                </div>
              </div>
            </div>

            <div className="col-auto  d-md-flex align-items-md-stretch">
              <div className="count-box py-3 py-lg-5 d-flex gap-4  justify-content-lg-center">
                <img className="d-none d-md-block" src="content/images/counter2.png" />
                <img className="d-block d-md-none" src="content/images/counter2-mobile-view.png" alt="mobileView" />
                <div className="about-numbers text-white fw-bolder fs-3 d-md-block d-none">
                  <span className="purecounter">Free Shipping</span>
                  <p className="mb-0">In the Continental US</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-4 d-md-flex align-items-md-stretch">
              <div className="count-box py-3 py-lg-5 d-flex gap-4 justify-content-lg-start">
                <img src="content/images/dentist-tested-badge.png" />
                <div className="about-numbers text-white fw-bolder fs-3 d-md-block d-none">
                  <span className="purecounter ">Dentist</span>
                  <p className="mb-0"> Tested</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  New section start --> */}
      <section className="second-section" id="second-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-12">
              <div className="new-section">
                <img src="content/images/productImageWithFucntions.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  feature section start --> */}
      <section className="feature-section" id="feature">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="embed-responsive video-design">
                <video
                  className="embed-responsive-item"
                  src="content/images/feature.mp4"
                  autoPlay={true}
                  controls={true}
                  loop={true}
                  muted={true}
                  style={{ width: '100%' }}
                >
                  <source type="video/mp4" src="content/images/feature.mp4" />
                </video>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="heading-design starr fs-md-1 fs-2 mb-4 mt-4  mb-3 text-md-start text-center d-md-inline-block d-block">
                More Features And Benefits
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/braces.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Designed to clean around hardware and implants</h3>
                </div>
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/braces2.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Aids in removal of food residue to promote gum health</h3>
                </div>
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/clean-tooth.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Helps lessen the appearance of coffee and wine stains</h3>
                </div>
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/mouthwash.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Works with your favorite mouthwash</h3>
                </div>
              </div>
              <ScrollLink
                activeClass="active"
                className="btn btn-primary content-btn mt-md-5 d-block mx-md-0 mx-auto mt-3"
                to="order"
                spy={true}
                smooth={true}
                duration={500}
              >
                Order Now - Up To 30% Off
              </ScrollLink>
            </div>
          </div>
        </div>
      </section>

      {/* <!--  feature section end --> */}

      {/* <!--  Smiles-section start --> */}
      {/* <section id="Smiles-section" className="Smiles-section">
        <div className="container">
          <div className="text-center bundle-gap">
            <div className="display-4 heading-design fs-md-1 fs-2 mb-4"> Great for All These Smiles And More</div>
          </div>

          <div className="row mt-3 mt-md-5">
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="Smiles-inner position-relative">
                <img src="content/images/1.png" />
                <div className="fs-5 smile_bg py-md-4 py-3 text-center fw-bold mt-1">Sensitive Teeth</div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="Smiles-inner position-relative">
                <img src="content/images/2.png" />
                <div className="fs-5 smile_bg py-md-4 py-3 text-center fw-bold mt-1">Crowns & Veneers</div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="Smiles-inner position-relative">
                <img src="content/images/3.png" />
                <div className="fs-5 smile_bg py-md-4 py-3 text-center fw-bold mt-1">Braces</div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <div className="Smiles-inner position-relative">
                <img src="content/images/4.png" />
                <div className="fs-5 smile_bg py-md-4 py-3 text-center fw-bold mt-1">Dentures & Implants</div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <!--  Smiles-section end --> */}

      {/* <!--  Finally Enjoy Flossing start --> */}
      <section className="feature-section  enjoyFlossing " id="feature">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <div className="heading-design starr fs-md-1 fs-2 mb-4 mt-0 d-md-inline-block d-block text-md-start text-center">
                Finally Enjoy Flossing
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/teath.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Built for those with gum and mouth sensitivity</h3>
                </div>
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/braces.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Stop struggling to clean around braces, bridges, etc.</h3>
                </div>
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/dental-floss.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">No more wasteful and difficult sticks and strings</h3>
                </div>
              </div>
              <div className="icon-box d-flex align-items-center pb-3">
                <div className="icon-box-icon pt-3 pe-3 pb-3 ps-3">
                  <img src="content/images/perfect.svg" />
                </div>
                <div className="icon-box-content ps-3">
                  <h3 className="card-title fs-4 text-dark">Perfect for all ages</h3>
                </div>
              </div>
              <ScrollLink
                activeClass="active"
                className="btn btn-primary content-btn mt-md-5 mt-3 mb-3 d-block mx-md-0 mx-auto"
                to="order"
                spy={true}
                smooth={true}
                duration={500}
              >
                Order Now - Up To 30% Off
              </ScrollLink>
            </div>
            <div className="col-lg-6 col-12">
              <img style={{ width: '100%' }} className="rounded-3 d-none d-md-block" src="content/images/smiling.png" />
            </div>
          </div>
        </div>
      </section>

      {/* <!--  Finally Enjoy Flossing end --> */}

      {/* <!--  flossing-section start --> */}
      <section id="flossing-section" className="flossing-section py-5">
        <div className="container">
          <div className="text-center bundle-gap">
            <div className="display-4 heading-design fs-md-1 fs-2 mb-1"> Miracle Smile® makes flossing as easy as</div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-6 col-xxl-4 mb-1 mb-md-5">
              <div className=" border-0 h-100">
                <div className="feature-text text-white rounded-circle fw-bold mb-4 mt-n4">1</div>
                <div className="pt-0 pt-lg-0 desogn-flossing position-relative">
                  <img src="content/images/21.png" style={{ width: '100%' }} />
                  <div className="py-4 mobile-view-overlay">
                    <h2 className="fs-2 fw-bold text-center">Charge</h2>
                    <p className="mb-0 fs-4 text-center" style={{ fontWeight: 600 }}>
                      Fast and easy with dock
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xxl-4 mb-1 mb-md-5">
              <div className=" border-0 h-100">
                <div className="feature-text text-white rounded-circle fw-bold mb-4 mt-n4">2</div>
                <div className="pt-0 pt-lg-0 desogn-flossing position-relative">
                  <img src="content/images/23.png" style={{ width: '100%' }} />
                  <div className="py-4 mobile-view-overlay">
                    <h2 className="fs-2 fw-bold text-center">Fill</h2>
                    <p className="mb-0 fs-4 text-center" style={{ fontWeight: 600 }}>
                      Large capacity tank
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xxl-4 mb-1 mb-md-5">
              <div className=" border-0 h-100">
                <div className="feature-text text-white rounded-circle fw-bold mb-4 mt-n4">3</div>
                <div className="pt-0 pt-lg-0 desogn-flossing position-relative">
                  <img src="content/images/24.png" style={{ width: '100%' }} />
                  <div className="py-4 mobile-view-overlay">
                    <h2 className="fs-2 fw-bold text-center">Floss</h2>
                    <p className="mb-0 fs-4 text-center" style={{ fontWeight: 600 }}>
                      In seconds
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  flossing-section end --> */}

      {/* <!--  dentist Recommended start --> */}
      <section className="dentistRecommended-section py-5" id="dentistRecommended">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <img style={{ width: '100%' }} className="rounded-3" src="content/images/DentistRecommended.png" />
            </div>
            <div className="col-lg-6 col-12 dentist_space">
              <div className="heading-design fs-md-1 fs-2 mt-4 m-md-0">Dentist Tested</div>
              <div className="i">
                <p>“Brushing alone is not enough! With Miracle Smile® the water can get into those crevices that are harder to reach."</p>
                <div className="mt-4 sky">Dr. Donna Lieu, DDS</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  dentist Recommended start --> */}

      {/* <!--  testimonial section start --> */}

      <Element name="order" id="bundle-section" className="bundle-section pt-5">
        <div className="container">
          <div className="text-center bundle-gap">
            <div className="heading-design fs-md-1 fs-2 mb-1"> Bundle And Save Up To 30%</div>
            <div className="star">
              <img src="content/images/Star.png" />
              Fast and Free Shipping in the Continental US
            </div>
          </div>

          <div className={posthogFlag == 'test' ? 'row bundle-row-to-col' : 'row'}>
            {posthogFlag == 'test'
              ? dataList.map((product, index) => (
                  <div className="col-xl-3 col-md-6 mb-3">
                    <div className="bundle-content abtest" onClick={() => addToCart(product.id)}>
                      <div className="d-flex justify-centent-between gap-3 align-items-end">
                        <img className="img-size" src={product.productAsserts.length > 0 && getImageUrl(product.productAsserts[0])} />
                        <h3 className="fs-5">{product.name}</h3>
                      </div>
                      {product.description && (
                        <p className="pt-3 fs-5 mb-0 sv-bundle align-items-center">
                          {`${product.description} ${product.discountPercentage}%`}
                        </p>
                      )}
                      <div className="price fs-1">
                        {product.msrp && <span className="pt-1 text-decoration-line-through mt-3 fs-5 mb-0">${product.msrp}</span>}&nbsp;$
                        {product.price}
                        {/* {product.price > 100 ? <span>Free S&H</span> : <span>Plus S&H</span>} */}
                      </div>
                    </div>
                    <div className="abtest buy-btn">
                      <button
                        className="btn btn-primary"
                        onClick={e => {
                          addToCart(product.id);
                        }}
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>
                ))
              : dataList.map((product, index) => (
                  <div className="col-xl-3 col-md-6 mb-3" onClick={() => addToCart(product.id)}>
                    <div className="bundle-content">
                      <div className="d-flex justify-centent-between gap-3 align-items-end">
                        <img className="img-size" src={product.productAsserts.length > 0 && getImageUrl(product.productAsserts[0])} />
                        <h3 className="fs-5">{product.name}</h3>
                      </div>
                      {product.description && (
                        <p className="pt-3 fs-5 mb-0 sv-bundle align-items-center">
                          {product.description} <b>{product.discountPercentage}%</b>
                        </p>
                      )}
                      <div className="buy-btn">
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            addToCart(product.id);
                          }}
                        >
                          Buy Now
                        </a>
                      </div>
                      <div className="price fs-1">
                        {product.msrp && <span className="pt-1 text-decoration-line-through mt-3 fs-5 mb-0">${product.msrp}</span>}&nbsp;$
                        {product.price}
                        {/* {product.price > 100 ? <span>Free S&H</span> : <span>Plus S&H</span>} */}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {!hideItems && (
            <div className="row text-center payment mt-5 py-md-5 pb-0">
              <div className="container">
                <img src="content/images/payment.png" />
              </div>
            </div>
          )}
        </div>
      </Element>

      {hideItems && (
        <div className="row payment">
          <div className="container">
            <Checkout cartId={cartId} hideItems={hideItems} check={atcCountCheck} />
          </div>
        </div>
      )}

      {/* <!--  testimonial section start --> */}
      <section id="testimonial_slide" className={hideItems ? 'testimonial_slide py-5 mt-5' : 'testimonial_slide py-5'}>
        <div className="container">
          <div className="heading-design text-center fs-md-1 fs-2 mb-md-5 mb-3"> Customer Reviews</div>
          <Slider {...settings} className="testimonial-slider">
            <div className="slide-item">
              <div className="row align-items-center h-100">
                <div className="col-12 col-md-6 h-100">
                  <div className="review-content">
                    <img className="quote-space" src="content/images/teenyicons_quote-solid.svg" />
                    <h4>With Miracle Smile® I'm able to rinse all around my gums and the hard to reach places. </h4>
                    <div className="review-content-inner">
                      <img src="content/images/rating.png" />
                      <div className="author fs-5"> Meredith</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 h-100">
                  <img className="slide-img d-none d-md-block" src="content/images/Meredith.png" />
                </div>
              </div>
            </div>
            <div className="slide-item">
              <div className="row h-100 align-items-center">
                <div className="col-12 col-md-6 h-100">
                  <div className="review-content">
                    <img className="quote-space" src="content/images/teenyicons_quote-solid.svg" />
                    <h4>
                      It’s just so easy to use, it’s getting those hard to reach places and it helps lessen the appearance of coffee and tea
                      stains.
                      <br></br> Now I look forward to flossing.{' '}
                    </h4>
                    <div className="review-content-inner">
                      <img src="content/images/rating.png" />
                      <div className="author fs-5"> Brandy</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 h-100">
                  <img className="slide-img d-none d-md-block" src="content/images/Meredith2.png" />
                </div>
              </div>
            </div>
            <div className="slide-item">
              <div className="row align-items-center h-100">
                <div className="col-12 col-md-6 h-100">
                  <div className="review-content">
                    <img className="quote-space" src="content/images/teenyicons_quote-solid.svg" />
                    <h4>It's not only super easy to use, it's fast! Now there is no excuse not to floss </h4>
                    <div className="review-content-inner">
                      <img src="content/images/rating.png" />
                      <div className="author fs-5"> Tim</div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 h-100">
                  <img className="slide-img d-none d-md-block" src="content/images/Meredith3.png" />
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* <!--  faq section start --> */}
      <section id="faq" className="faq inner-space py-5">
        <div className="container">
          <div className="row">
            <h3 className="heading-design fs-md-1 fs-2 mb-1 text-center">Frequently Asked Questions</h3>
            <div className="faq-right">
              <div className="faq-main">
                {faqs.map(obj => (
                  <div className="faq-item">
                    <div className="faq-label fs-5 fw-bold">
                      {obj.question}
                      <i></i>
                    </div>
                    <div className="faq-cont fs-5">
                      <p>{obj.answer}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!--  faq section end --> */}
    </div>
  );
};

export default ProductListView;
