import './header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, NavLink, NavItem } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Link as ScrollLink } from 'react-scroll';
import { Home, Brand } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../menus';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const openCart = () => navigate(`/cart`);
  const openCheckout = () => navigate('/checkout');
  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <>
      {props.isAuthenticated ? (
        <>
          <header id="header">
            <nav data-cy="navbar" className="navbar shadow navbar-expand-md">
              <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                  <img src="content/images/logo.svg" />
                </Link>
                <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
                <Collapse isOpen={menuOpen} navbar>
                  <Nav id="header-tabs" className="ms-auto dashboard" navbar>
                    {props.isAuthenticated && !props.isAdmin && (
                      <>
                        <NavItem>
                          <NavLink tag={Link} to="/order" className="d-flex align-items-center">
                            <FontAwesomeIcon icon="asterisk" />
                            <span>Orders</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} to="/reports/sales" className="d-flex align-items-center">
                            <FontAwesomeIcon icon="asterisk" />
                            <span>Sales Report</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink tag={Link} to="/reports/orders" className="d-flex align-items-center">
                            <FontAwesomeIcon icon="asterisk" />
                            <span>Orders Report</span>
                          </NavLink>
                        </NavItem>
                      </>
                    )}
                    {props.isAuthenticated && props.isAdmin && <Home />}
                    {props.isAuthenticated && props.isAdmin && <EntitiesMenu />}
                    {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
                    <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                    <AccountMenu isAuthenticated={props.isAuthenticated} isAdmin={props.isAdmin} />
                  </Nav>
                </Collapse>
              </div>
            </nav>
          </header>
        </>
      ) : (
        <header id="header">
          <nav className="navbar shadow">
            <div className="container">
              <Link className="navbar-brand" to="/">
                <img src="content/images/logo.svg" />
              </Link>
              <div className="shipping-nav">
                <img src="content/images/Star.png" /> Free Shipping In the Continental US <img src="content/images/Star.png" />
              </div>
              <div className="d-flex align-items-center">
                {location.pathname != '/cart' ? (
                  <div className="me-4 cart" onClick={openCart}>
                    <img src="content/images/shopping-bag.png" />
                    CART
                  </div>
                ) : (
                  <button className="btn btn-secondary content-btn " onClick={openCheckout}>
                    CHECKOUT
                  </button>
                )}
                {location.pathname == '/' && (
                  <ScrollLink
                    activeClass="active"
                    className="btn btn-primary fw-bolder header-btn"
                    to="order"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    ORDER NOW
                  </ScrollLink>
                )}
              </div>
            </div>
          </nav>
        </header>
      )}
    </>
  );
};

export default Header;
