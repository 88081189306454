import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';

export interface IPaymentGateway {
  id?: number;
  tryOrder?: number;
  username?: string | null;
  password?: string | null;
  name?: string | null;
  industryName?: string | null;
  paymentType?: PaymentType;
}

export const defaultValue: Readonly<IPaymentGateway> = {};
