import { IProduct } from 'app/shared/model/product.model';

export interface IProductCategory {
  id?: number;
  name?: string | null;
  active?: boolean | null;
  highlighted?: boolean | null;
  products?: IProduct[] | null;
}

export const defaultValue: Readonly<IProductCategory> = {
  active: false,
  highlighted: false,
};
