import OrderListView from './OrderListView';

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
interface Props {}

export const OrderReport = (props: Props) => {
  return (
    <Row>
      <Col md="12" className="mx-5">
        <h1>Orders Report</h1>
      </Col>

      <Col md="12" className="mb-5">
        <OrderListView />
      </Col>

      <span id="custom-components" />
    </Row>
  );
};

export default OrderReport;
