import { getEntities, reset, deleteEntity, updateEntity } from 'app/entities/product-in-cart/product-in-cart.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

interface Props {}
export const Cart = (props: Props) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const dataList = useAppSelector(state => state.productInCart.entities);
  const [quantityOfProduct, setQuantityOfProduct] = useState({});
  const cartId = parseInt(localStorage.getItem('cartId'));
  const updateSuccessProductInCart = useAppSelector(state => state.productInCart.updateSuccess);

  useEffect(() => {
    if (cartId) {
      dispatch(getEntities({ query: `cartId.equals=${cartId}` }));
    } else {
      dispatch(reset());
    }
    localStorage.setItem('totalPrice', getTotalPrice);
  }, []);

  useEffect(() => {
    if (updateSuccessProductInCart) {
      dispatch(getEntities({ query: `cartId.equals=${cartId}` }));
    }
    localStorage.setItem('totalPrice', getTotalPrice);
  }, [updateSuccessProductInCart]);

  const checkout = () => {
    navigate('/checkout');
  };
  const getImageUrl = imageObj => {
    if (imageObj.path != '') {
      return imageObj.path;
    } else {
      return `content/images/${imageObj.name}`;
    }
  };
  const handleQuantityChange = async (productId, change, saveQuantity) => {
    setQuantityOfProduct(prevQuantities => {
      const updatedQuantity = (prevQuantities[productId] || saveQuantity) + change;
      // Apply quantity constraints
      const constrainedQuantity = Math.min(Math.max(updatedQuantity, 1), 5);
      let productInCartObj = dataList.filter(item => item.id == productId)[0];
      productInCartObj = { ...productInCartObj, quantity: constrainedQuantity };
      dispatch(updateEntity(productInCartObj));

      return {
        ...prevQuantities,
        [productId]: constrainedQuantity,
      };
    });
  };

  const removeProduct = productId => {
    let productRemoveDetails = dataList.filter(
      item => item.product.name.toLowerCase().indexOf('miracle smile') > -1 && item.id == productId
    )[0];
    let getExtendedWarranty = dataList.filter(obj => obj.product.name.toLowerCase() == 'extended warranty')[0];
    if (productRemoveDetails && getExtendedWarranty) {
      let updateExtendedWarranty = getExtendedWarranty.quantity - productRemoveDetails.quantity;
      getExtendedWarranty = { ...getExtendedWarranty, quantity: updateExtendedWarranty };
      dispatch(updateEntity(getExtendedWarranty));
    }
    dispatch(deleteEntity(productId));
  };

  let getTotalPrice = dataList && dataList.map(obj => obj?.quantity * obj?.price || 0);
  getTotalPrice = getTotalPrice.length > 0 ? getTotalPrice.reduce((a, b) => a + b).toFixed(2) : 0.0;

  return (
    <>
      <section className={dataList.length > 0 ? 'cart-baner' : 'cart-empty-design'} id="cart-baner">
        <div className="container">
          <div className="row text-center">
            <div className="display-4 cart-heading fs-1 mb-1"> {dataList.length > 0 ? 'View Cart' : 'Cart is Empty'}</div>
            <div className="breadcrumb justify-content-center">
              {dataList.length == 0 && (
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Keep Shopping</Link>
                  </li>
                  {/* <li>Cart</li> */}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- banner Section End--> */}
      {dataList.length > 0 ? (
        <section className="cart-product" id="cart-product">
          <div className="container">
            <div className="row">
              <div className="shopping-cart">
                <div className="column-labels">
                  <div className="row">
                    <div className="col-md-4 col-3">
                      <label className="product-product fs-4 fw-bolder">PRODUCT </label>
                    </div>
                    <div className="col-md-2 col-2 text-center">
                      <label className="product-price fs-4 fw-bolder"> PRICE</label>
                    </div>
                    <div className="col-md-3 col-3 text-center">
                      <label className="product-quantity fs-4 fw-bolder">QUANTITY</label>
                    </div>
                    <div className="col-md-2 col-3">
                      <label className="product-total fs-4 fw-bolder">TOTAL</label>
                    </div>
                  </div>
                </div>
                {/* <!-- cart product --> */}

                {dataList.map((item, i) => (
                  <div className="product mb-5">
                    <div className="row align-items-center">
                      <div className="col-md-4 col-3">
                        <div className="product-product fs-5">
                          {' '}
                          <img src={item?.product?.productAsserts?.length > 0 && getImageUrl(item.product.productAsserts[0])} />
                          <span> {item.product.name}</span>
                        </div>
                      </div>
                      <div className="col-md-2 col-2 text-center">
                        <div className="product-price">
                          <p className="fw-bolder mb-0">${item.product.price}</p>
                        </div>
                      </div>
                      <div className="col-md-3 col-4 text-center">
                        <div className="product-quantity">
                          {item.product.productCategory.name != 'quantity' ? (
                            <div className="d-flex justify-content-center">
                              <div className="input-group w-auto ">
                                <input
                                  type="number"
                                  step="1"
                                  max="10"
                                  value={quantityOfProduct[item.id] || item.quantity}
                                  name="quantity"
                                  className="quantity-field border-0 text-center "
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex justify-content-center">
                              <div className="input-group w-auto flex-nowrap">
                                <input
                                  type="button"
                                  value="-"
                                  className="button-minus border rounded-circle  icon-shape icon-sm mx-1 "
                                  data-field="quantity"
                                  onClick={() => handleQuantityChange(item.id, -1, item.quantity)}
                                />
                                <input
                                  type="number"
                                  step="1"
                                  max="10"
                                  value={quantityOfProduct[item.id] || item.quantity}
                                  name="quantity"
                                  className="quantity-field border-0 text-center "
                                />
                                <input
                                  type="button"
                                  value="+"
                                  className="button-plus border rounded-circle icon-shape icon-sm "
                                  data-field="quantity"
                                  onClick={() => handleQuantityChange(item.id, 1, item.quantity)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2 col-2">
                        <div className="product-total">
                          <p className="fw-bolder mb-0">${item.price * item.quantity}</p>
                        </div>
                      </div>
                      <div className="col-md-1 col-1">
                        <img
                          src="content/images/close.png"
                          onClick={() => {
                            removeProduct(item.id);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                    </div>
                    <hr></hr>
                  </div>
                ))}

                {/* <!-- cart product end --> */}
                <div className="column-labels">
                  <div className="row">
                    <div className="col-md-5 col-5"></div>
                    <div className="col-md-2 col-2"></div>
                    <div className="col-md-3 col-3">
                      <label className="product-quantity fs-4 fw-bolder">subtotal</label>
                    </div>
                    <div className="col-md-2 col-2">
                      <label className="product-total fs-4 fw-bolder">${getTotalPrice}</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 col-12"></div>
                  <div className="col-md-4 col-lg-2 col-4">
                    <div className="checkout-btn">
                      <button className="btn btn-secondary content-btn mt-5 mb-3" onClick={checkout}>
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ''
      )}
    </>
  );
};

export default Cart;
