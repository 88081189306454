import { IProduct } from 'app/shared/model/product.model';
import { AssertType } from 'app/shared/model/enumerations/assert-type.model';

export interface IProductAssert {
  id?: number;
  name?: string | null;
  path?: string | null;
  type?: AssertType;
  product?: IProduct | null;
}

export const defaultValue: Readonly<IProductAssert> = {};
