import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntities } from 'app/entities/product/product.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './order.reducer';
import { createEntity } from './order-modify.reducer';
import OrderCancelDialog from './order-cancel-dialog';

export const OrderModify = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [orderId, setOrderId] = useState('');
  const productList = useAppSelector(state => state.product.entities);
  const orderEntity = useAppSelector(state => state.order.entity);
  const orderModifyUpdate = useAppSelector(state => state.orderModify.updateSuccess);
  const [orderItems, setOrderItems] = useState(orderEntity);
  const [currentProduct_Id, setCurrentProduct_Id] = useState('');
  const [newProductId, setNewProductId] = useState('');

  const [orderItemsCopy, setOrderItemsCopy] = useState({ items: [] });

  const [quantityOfProduct, setQuantityOfProduct] = useState({});

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getEntities({ query: `productCategoryId.equals=1` }));
  }, []);

  let subTotalPrices = orderEntity && orderEntity.productInCarts && orderEntity.productInCarts.map(obj => obj?.quantity * obj?.price || 0);
  subTotalPrices = subTotalPrices?.length > 0 ? subTotalPrices.reduce((a, b) => a + b).toFixed(2) : 0.0;
  const calculateTotal = (orderDetails, propName) => {
    const payments = orderDetails?.payments || [];
    const values = payments.map(obj => obj?.[propName] || 0);

    const total = values.reduce((a, b) => a + b, 0);
    return total > 0 ? total.toFixed(2) : '0.00';
  };
  const getEmailAdress = orderEntity?.addresses?.length > 0 ? orderEntity?.addresses[0].email : '';
  const cancelOrder = orderId => {
    setshowCancelModal(true);
    setOrderId(orderId);
  };
  const viewModal = modal => {
    setshowCancelModal(modal);
  };

  const removeProductId = (oldItems, productIdToRemove) => {
    let idx = oldItems.findIndex(ele => ele.productId == productIdToRemove);
    if (idx > -1) {
      oldItems.splice(idx, 1);
    }
    return oldItems;
  };

  const addProductId = (oldItems, product) => {
    let idx = oldItems.findIndex(ele => ele.productId == product.productId);
    if (idx > -1) {
      oldItems[idx] = product;
    } else {
      oldItems.push(product);
    }
    return oldItems;
  };
  const handleProductUpdate = (event, oldProductId) => {
    let currentProductId = event.target.value;
    let productObject = productList.filter(item => item.id == currentProductId)[0];
    let oldItems = orderItemsCopy.items;

    if (productObject.id !== oldProductId) {
      let newProductObj = { productId: currentProductId, quantity: 1 };
      oldItems = removeProductId(oldItems, newProductId);
      setNewProductId(currentProductId);
      let oldProductObj = { productId: oldProductId, quantity: 0 };
      oldItems = addProductId(oldItems, newProductObj);
      oldItems = addProductId(oldItems, oldProductObj);
    } else {
      oldItems = removeProductId(oldItems, newProductId);
      setNewProductId('');
      oldItems = removeProductId(oldItems, oldProductId);
    }

    setOrderItemsCopy({ items: oldItems });
    setCurrentProduct_Id(currentProductId);
  };

  const handleQuantityChange = async (productId, incDec, oldQuantity) => {
    setQuantityOfProduct(prevQuantities => {
      let newProductQuantiy = (prevQuantities[productId] != undefined ? prevQuantities[productId] : oldQuantity) + incDec;

      let currentQuantityValidated = Math.min(Math.max(newProductQuantiy, 0), oldQuantity);

      let productInCartObj = orderItems?.productInCarts?.filter(item => item.product.id == productId)[0];
      productInCartObj = { ...productInCartObj, currentQuantity: currentQuantityValidated };
      let tempProductInCart = orderItems?.productInCarts?.map(item => {
        return item.product.id == productId ? productInCartObj : item;
      });
      setOrderItems(preOrderDetails => {
        return { ...preOrderDetails, productInCarts: tempProductInCart };
      });

      if (productInCartObj.currentQuantity < productInCartObj.quantity) {
        let newObj = { productId: productId, quantity: currentQuantityValidated };
        let oldItems = addProductId(orderItemsCopy.items, newObj);
        setOrderItemsCopy({ items: oldItems });
      } else {
        let oldItems = orderItemsCopy.items;
        oldItems = oldItems.filter(obj => {
          if (obj.productId != productId) {
            return obj;
          }
        });
        setOrderItemsCopy({ items: oldItems });
      }

      return {
        ...prevQuantities,
        [productId]: newProductQuantiy,
      };
    });
  };

  const updateQuantity = async () => {
    let newOrderObj = { orderId: orderItems.id, items: orderItemsCopy.items };
    await dispatch(createEntity(newOrderObj));
  };
  useEffect(() => {
    if (orderModifyUpdate) {
      navigate('/order');
    }
  }, [orderModifyUpdate]);

  useEffect(() => {
    setOrderItems(orderEntity);
  }, [orderEntity]);

  return (
    <div className="table-responsive">
      <table
        align="center"
        cellPadding={0}
        cellSpacing={0}
        width="100%"
        style={{ maxWidth: 800, padding: '50px 15px', margin: '80px auto' }}
      >
        <tbody>
          <tr>
            <td colSpan={2}>
              <h3
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginBottom: 50,
                }}
              >
                <b>Order: #{orderEntity.orderNumber || orderEntity.id}</b>
              </h3>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: 0, paddingRight: 0, width: '50%' }} valign="top">
              <span style={{ fontSize: 14 }}>
                <b>Status : </b>
              </span>
              <span style={{ fontSize: 14 }}>{orderEntity.status}</span>
            </td>
            {orderEntity.status != 'CANCELLED' && orderEntity.status != 'SHIPPED' && (
              <td
                style={{
                  fontSize: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                  textAlign: 'right',
                  width: '50%',
                }}
              >
                <button
                  style={{
                    fontSize: 14,
                    border: '1px solid #dc3545',
                    padding: '6px 13px',
                    borderRadius: 4,
                    background: '#dc3545',
                    color: '#fff',
                  }}
                  onClick={() => {
                    cancelOrder(orderEntity.id);
                  }}
                >
                  Cancel Order
                </button>
              </td>
            )}
          </tr>

          <tr>
            <td>
              <span style={{ fontSize: 14 }}>
                <b>Email : </b>
              </span>
              <span style={{ fontSize: 14 }}>{getEmailAdress}</span>
            </td>
          </tr>

          <tr>
            <td style={{ paddingTop: '10px', paddingBottom: 0 }} colSpan={2}>
              <b>Order Items:</b>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Table
                style={{
                  borderColor: '#f2f2f2',
                  borderWidth: 1,
                  background: '#fafafa',
                  margin: '20px auto 0px',
                  width: '100%',
                }}
                cellSpacing={0}
                cellPadding={0}
                border={1}
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        textAlign: 'left',
                        borderWidth: 1,
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                        width: '40%',
                      }}
                    >
                      {' '}
                      Products Name
                    </td>

                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'center',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                        width: '20%',
                      }}
                    >
                      Quantity
                    </td>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                        width: '15%',
                      }}
                    >
                      {' '}
                      Unit
                    </td>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                        width: '15%',
                      }}
                    >
                      Discount
                    </td>

                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                        width: '10%',
                      }}
                    >
                      Total
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: 0 }} />
                  </tr>
                  {orderItems?.productInCarts?.length > 0 &&
                    orderItems?.productInCarts?.map((item, index) => (
                      <tr>
                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          {item.product.productCategory.name == 'homepage' ? (
                            <select
                              className="custom-select form-control d-block w-100"
                              id="product"
                              value={currentProduct_Id || item.product.id}
                              onChange={e => {
                                handleProductUpdate(e, item.product.id);
                              }}
                              name="product"
                              required
                              disabled={item.currentQuantity == 0}
                            >
                              {productList.map(product => {
                                if (product.id <= item.product.id) {
                                  return (
                                    <option value={product.id} selected={product.id === item.product.id}>
                                      {product.name}
                                    </option>
                                  );
                                }
                              })}
                            </select>
                          ) : (
                            item.product.name
                          )}
                        </td>

                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'center',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          <div className="order-modify">
                            <div className="input-group w-auto flex-nowrap">
                              {!newProductId || item.product.productCategory.name != 'homepage' ? (
                                <input
                                  type="button"
                                  value="-"
                                  className="button-minus border rounded-circle  icon-shape icon-sm mx-1 "
                                  data-field="quantity"
                                  onClick={() => handleQuantityChange(item.product.id, -1, item.quantity)}
                                />
                              ) : (
                                ''
                              )}
                              <input
                                type="number"
                                step="0"
                                max="10"
                                value={item.currentQuantity != undefined ? item.currentQuantity : item.quantity}
                                name="quantity"
                                className="quantity-field border-0 text-center "
                              />

                              {item.currentQuantity < item.quantity ? (
                                <input
                                  type="button"
                                  value="+"
                                  className="button-plus border rounded-circle icon-shape icon-sm "
                                  data-field="quantity"
                                  onClick={() => handleQuantityChange(item.product.id, +1, item.quantity)}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        </td>

                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          ${item.price}
                        </td>
                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          ${item.discount ? item.discount : (0).toFixed(2)}
                        </td>

                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            textAlign: 'left',
                            borderWidth: 1,
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          ${item.price * (item.currentQuantity != undefined ? item.currentQuantity : item.quantity)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b> Shipping</b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        borderBottom: '1px solid #ddd',
                        textAlign: 'left',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    ></td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        borderBottom: '1px solid #ddd',
                        textAlign: 'left',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    ></td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        borderBottom: '1px solid #ddd',
                        textAlign: 'right',
                        color: '#333',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        {}
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        ${calculateTotal(orderEntity, 'shippingCharge')}
                        <b />
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>Tax</b>
                    </td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',

                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>${calculateTotal(orderEntity, 'tax')}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        borderBottom: '1px solid #ddd',
                        textAlign: 'left',
                        color: '#162f70',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>Total</b>
                    </td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#162f70',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#162f70',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        borderBottom: '1px solid #ddd',
                        textAlign: 'right',
                        color: '#162f70',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        textAlign: 'right',
                        borderBottom: '1px solid #ddd',
                        fontSize: '14px',
                        color: '#162f70',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>${calculateTotal(orderEntity, 'paymentAmount')}</b>
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      colSpan={5}
                      style={{
                        textAlign: 'right',
                        background: '#ddd',
                        borderWidth: 1,
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>Subtotal</b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        background: '#ddd',
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>${subTotalPrices}</b>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table cellSpacing={0} cellPadding={0} width="100%">
                <tbody>
                  <tr>
                    <td style={{ padding: '20px 0' }}>
                      <b>Address Details:</b>
                    </td>
                  </tr>

                  <tr>
                    {orderEntity &&
                      orderEntity.addresses &&
                      orderEntity.addresses.map(item => (
                        <td style={{ border: 0, paddingRight: '10px', width: '50%' }}>
                          <div style={{ background: '#ddd', padding: '20px' }}>
                            {' '}
                            <b
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                              }}
                            >
                              {item.type}
                            </b>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.firstName + '' + item.lastName}
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.street}
                            </p>
                            {item.street2 && (
                              <p
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  marginTop: 0,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                  margin: 0,
                                }}
                              >
                                {item.street2}
                              </p>
                            )}
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                marginTop: 0,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.city + ' ' + item.state + ' ' + item.countryCode}
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                marginTop: 0,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.contact}
                            </p>
                          </div>
                        </td>
                      ))}
                  </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/order" replace color="info" className="mt-3">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              {orderItemsCopy?.items?.length != 0 && (
                <Button
                  color="primary"
                  id="save-entity"
                  data-cy="entityCreateSaveButton"
                  type="submit"
                  className="mt-3"
                  onClick={updateQuantity}
                >
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Update
                </Button>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {showCancelModal && <OrderCancelDialog orderId={orderId} showCancelModal={viewModal} />}
    </div>
  );
};

export default OrderModify;
