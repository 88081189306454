import { IOrder } from 'app/shared/model/order.model';
import { AddressType } from 'app/shared/model/enumerations/address-type.model';

export interface IAddress {
  id?: number;
  countryCode?: string;
  title?: string | null;
  street?: string | null;
  street2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string;
  contact?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  type?: AddressType | null;
  email?: string | null;
  order?: IOrder | null;
}

export const defaultValue: Readonly<IAddress> = {};
