import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Tag from './tag';
import ProductCategory from './product-category';
import Product from './product';
import Cart from './cart';
import Order from './order';
import Payment from './payment';
import ProductTag from './product-tag';
import ProductInCart from './product-in-cart';
import DiscountCode from './discount-code';
import OrderStatus from './order-status';
import PaymentMethod from './payment-method';
import Address from './address';
import PaymentGateway from './payment-gateway';
import ProductAssert from './product-assert';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="tag/*" element={<Tag />} />
        <Route path="product-category/*" element={<ProductCategory />} />
        <Route path="product/*" element={<Product />} />
        <Route path="cart/*" element={<Cart />} />
        <Route path="order/*" element={<Order />} />
        <Route path="payment/*" element={<Payment />} />
        <Route path="product-tag/*" element={<ProductTag />} />
        <Route path="product-in-cart/*" element={<ProductInCart />} />
        <Route path="discount-code/*" element={<DiscountCode />} />
        <Route path="order-status/*" element={<OrderStatus />} />
        <Route path="payment-method/*" element={<PaymentMethod />} />
        <Route path="address/*" element={<Address />} />
        <Route path="product-assert/*" element={<ProductAssert />} />
        <Route path="payment-gateway/*" element={<PaymentGateway />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
