import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductAssert from './product-assert';
import ProductAssertDetail from './product-assert-detail';
import ProductAssertUpdate from './product-assert-update';
import ProductAssertDeleteDialog from './product-assert-delete-dialog';

const ProductAssertRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductAssert />} />
    <Route path="new" element={<ProductAssertUpdate />} />
    <Route path=":id">
      <Route index element={<ProductAssertDetail />} />
      <Route path="edit" element={<ProductAssertUpdate />} />
      <Route path="delete" element={<ProductAssertDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductAssertRoutes;
