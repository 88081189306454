import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './order.reducer';
import OrderCancelDialog from './order-cancel-dialog';

export const OrderDetail = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, [id]);
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [orderId, setOrderId] = useState('');
  const orderEntity = useAppSelector(state => state.order.entity);
  let subTotalPrices = orderEntity && orderEntity.productInCarts && orderEntity.productInCarts.map(obj => obj?.quantity * obj?.price || 0);
  subTotalPrices = subTotalPrices?.length > 0 ? subTotalPrices.reduce((a, b) => a + b).toFixed(2) : 0.0;
  const calculateTotal = (orderDetails, propName) => {
    const payments = orderDetails?.payments || [];
    const values = payments.map(obj => obj?.[propName] || 0);

    const total = values.reduce((a, b) => a + b, 0);
    return total > 0 ? total.toFixed(2) : '0.00';
  };
  const getEmailAdress = orderEntity?.addresses?.length > 0 ? orderEntity?.addresses[0].email : '';
  const cancelOrder = orderId => {
    setshowCancelModal(true);
    setOrderId(orderId);
  };
  const viewModal = modal => {
    setshowCancelModal(modal);
  };

  return (
    <div className="table-responsive">
      <table
        align="center"
        cellPadding={0}
        cellSpacing={0}
        width="100%"
        style={{ maxWidth: 800, padding: '50px 15px', margin: '80px auto' }}
      >
        <tbody>
          <tr>
            <td colSpan={2}>
              <h3
                style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  marginBottom: 50,
                }}
              >
                <b>Order: #{orderEntity.orderNumber || orderEntity.id}</b>
              </h3>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: 0, paddingRight: 0, width: '50%' }} valign="top">
              <span style={{ fontSize: 14 }}>
                <b>Status : </b>
              </span>
              <span style={{ fontSize: 14 }}>{orderEntity.status}</span>
            </td>
            {orderEntity.status != 'CANCELLED' && orderEntity.status != 'SHIPPED' && (
              <td
                style={{
                  fontSize: 24,
                  paddingLeft: 0,
                  paddingRight: 0,
                  textAlign: 'right',
                  width: '50%',
                }}
              >
                <button
                  style={{
                    fontSize: 14,
                    border: '1px solid #dc3545',
                    padding: '6px 13px',
                    borderRadius: 4,
                    background: '#dc3545',
                    color: '#fff',
                  }}
                  onClick={() => {
                    cancelOrder(orderEntity.id);
                  }}
                >
                  Cancel Order
                </button>
              </td>
            )}
          </tr>

          <tr>
            <td>
              <span style={{ fontSize: 14 }}>
                <b>Email : </b>
              </span>
              <span style={{ fontSize: 14 }}>{getEmailAdress}</span>
            </td>
          </tr>

          <tr>
            <td style={{ paddingTop: '10px', paddingBottom: 0 }} colSpan={2}>
              <b>Order Items:</b>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Table
                style={{
                  borderColor: '#f2f2f2',
                  borderWidth: 1,
                  background: '#fafafa',
                  margin: '20px auto 0px',
                  width: '100%',
                }}
                cellSpacing={0}
                cellPadding={0}
                border={1}
                align="center"
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        textAlign: 'left',
                        borderWidth: 1,
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                      }}
                    >
                      {' '}
                      Products Name
                    </td>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                      }}
                    >
                      Quantity
                    </td>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                      }}
                    >
                      {' '}
                      Unit
                    </td>
                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                      }}
                    >
                      Discount
                    </td>

                    <td
                      style={{
                        fontWeight: 700,
                        fontSize: 14,
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#000',
                        padding: 10,
                        textTransform: 'capitalize',
                        background: '#ddd',
                      }}
                    >
                      Total
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: 0 }} />
                  </tr>
                  {orderEntity?.productInCarts?.length > 0 &&
                    orderEntity?.productInCarts?.map(item => (
                      <tr>
                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          {item.product.name}
                        </td>
                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          {item.quantity}
                        </td>
                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          ${item.price}
                        </td>
                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            borderWidth: 1,
                            textAlign: 'left',
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          ${item.discount ? item.discount : (0).toFixed(2)}
                        </td>

                        <td
                          style={{
                            fontWeight: 500,
                            fontSize: 14,
                            textAlign: 'left',
                            borderWidth: 1,
                            color: '#111',
                            padding: 10,
                            textTransform: 'capitalize',
                          }}
                        >
                          ${item.price * item.quantity}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b> Shipping</b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        borderBottom: '1px solid #ddd',
                        textAlign: 'left',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    ></td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        borderBottom: '1px solid #ddd',
                        textAlign: 'left',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    ></td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        borderBottom: '1px solid #ddd',
                        textAlign: 'right',
                        color: '#333',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        {}
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        ${calculateTotal(orderEntity, 'shippingCharge')}
                        <b />
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>Tax</b>
                    </td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',

                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>${calculateTotal(orderEntity, 'tax')}</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        borderBottom: '1px solid #ddd',
                        textAlign: 'left',
                        color: '#162f70',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>Total</b>
                    </td>

                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#162f70',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        textAlign: 'left',
                        borderBottom: '1px solid #ddd',
                        color: '#162f70',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        borderBottom: '1px solid #ddd',
                        textAlign: 'right',
                        color: '#162f70',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>
                        <b />
                      </b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        textAlign: 'right',
                        borderBottom: '1px solid #ddd',
                        fontSize: '14px',
                        color: '#162f70',
                        padding: '5px',
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>${calculateTotal(orderEntity, 'paymentAmount')}</b>
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      colSpan={5}
                      style={{
                        textAlign: 'right',
                        background: '#ddd',
                        borderWidth: 1,
                        fontWeight: 500,
                        fontSize: 14,
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>Subtotal</b>
                    </td>
                    <td
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        background: '#ddd',
                        borderWidth: 1,
                        textAlign: 'left',
                        color: '#111',
                        padding: 10,
                        textTransform: 'capitalize',
                      }}
                    >
                      <b>${subTotalPrices}</b>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table>
                <tbody>
                  <tr>
                    <td style={{ padding: '20px 0' }} colSpan={2}>
                      <b>Address Details:</b>
                    </td>
                  </tr>
                  <tr>
                    {orderEntity &&
                      orderEntity.addresses &&
                      orderEntity.addresses.map(item => (
                        <td style={{ border: 0, paddingRight: '10px', width: '50%' }}>
                          <div style={{ background: '#ddd', padding: '20px' }}>
                            {' '}
                            <b
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                              }}
                            >
                              {item.type}
                            </b>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.firstName + '' + item.lastName}
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.street}
                            </p>
                            {item.street2 && (
                              <p
                                style={{
                                  fontStyle: 'normal',
                                  fontWeight: 300,
                                  marginTop: 0,
                                  fontSize: '14px',
                                  lineHeight: '30px',
                                  color: '#000000',
                                  margin: 0,
                                }}
                              >
                                {item.street2}
                              </p>
                            )}
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                marginTop: 0,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.city + ' ' + item.state + ' ' + item.countryCode}
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                marginTop: 0,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                margin: 0,
                              }}
                            >
                              {item.contact}
                            </p>
                          </div>
                        </td>
                      ))}
                  </tr>
                </tbody>
              </Table>
            </td>
          </tr>

          <tr>
            <td>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to={location.state == null ? '/order' : location.state}
                replace
                color="info"
                className="mt-3"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
      {showCancelModal && <OrderCancelDialog orderId={orderId} showCancelModal={viewModal} />}
    </div>
  );
};

export default OrderDetail;
