import './footer.scss';
import React from 'react';

const TermsOfService = () => (
  <div className="container" style={{ maxWidth: '1390px' }}>
    <h3>TERMS OF SERVICE</h3>

    <h3>OVERVIEW</h3>
    <p>
      This website (the “Site”) is operated by Ontel Products Corporation (the “Company”) with address at 21 Law Drive, Fairfield, NJ 07004
      and email address <a href="mailto:miraclesmileadvanced@rephelpdesk.com">miraclesmileadvanced@rephelpdesk.com</a>
      <br />
      Throughout the Site, the terms “we”, “us” and “our” refer to the Company. The Company offers this Site, including all information,
      tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and
      notices stated here.
    </p>
    <p>
      By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms
      and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or
      available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers,
      vendors, customers, merchants, and/ or contributors of content.
    </p>
    <p>
      Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you
      agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not
      access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these
      Terms of Service.
    </p>
    <p>
      You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or
      replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this
      page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes
      acceptance of those changes.
    </p>
    <h3>SECTION 1 – ONLINE STORE TERMS</h3>
    <p>
      By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence,
      or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your
      minor dependents to use this site.
    </p>
    <p>
      You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your
      jurisdiction (including but not limited to copyright laws).
    </p>
    <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
    <p>A breach or violation of any of the Terms will result in an immediate termination of your Services.</p>
    <h3>SECTION 2 – GENERAL CONDITIONS</h3>
    <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
    <p>
      You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions
      over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card
      information is always encrypted during transfer over networks.
    </p>
    <p>
      You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the
      Service or any contact on the website through which the service is provided, without express written permission by us.
    </p>
    <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>
    <h3>SECTION 3 – ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h3>
    <p>
      We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is
      provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting
      primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own
      risk.
    </p>
    <p>
      This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your
      reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any
      information on our site. You agree that it is your responsibility to monitor changes to our site.
    </p>
    <h3>SECTION 4 – MODIFICATIONS TO THE SERVICE AND PRICES</h3>
    <p>Prices are exclusive of any tax and shipping costs which shall all be for your account.</p>
    <p>Prices for our products are subject to change without notice.</p>
    <p>
      We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.
    </p>
    <p>
      We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
    </p>
    <h3>SECTION 5 – PRODUCTS OR SERVICES (if applicable)</h3>
    <p>Prices are exclusive of any tax and shipping costs which shall all be for your account.</p>
    <p>
      Certain products or services may be available exclusively online through the Site. These products or services may have limited
      quantities and are subject to return or exchange only according Section 23 herein.
    </p>
    <p>
      We have made every effort to display as accurately as possible the colors and images of our products. We cannot guarantee that your
      computer monitor’s display of any color will be accurate.
    </p>
    <p>
      We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or
      jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or
      services that we offer. All descriptions of products or product pricing are subject to change at any time without notice, at our sole
      discretion. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is
      void where prohibited.
    </p>
    <p>
      We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet
      your expectations, or that any errors in the Service will be corrected.
    </p>
    <h3>SECTION 6 – SHIPPING</h3>
    <p>
      Shipping fees and associated costs (Shipping Charges) are all for your account. You may be charged USD/EUR/GBP 8.95 for Shipping
      Charges but actual Shipping Charges may be higher depending on your location and other factors. Shipping Charges for your order will
      be calculated and displayed at checkout.
    </p>
    <p>
      Delivery will take up to 30 days and delivery delays can occasionally occur. Shipping charges for your order will be calculated and
      displayed at checkout. We are not responsible for any customs and taxes applied to your order. All fees imposed during or after
      shipping are for your account (tariffs, taxes, etc.). We are not liable for any products damaged or lost during shipping. If you
      received your order damaged, please contact the shipment carrier to file a claim. Please save all packaging materials and damaged
      goods before filing a claim.
    </p>
    <p>
      For items not received, you can file a claim within sixty (60) days from order date. We reserve the right and discretion to reject
      claims after that time frame. For timely filed claims, we will:
    </p>
    <ul>
      <li>Open an investigation with our shipper (takes 7-10 days to complete);</li>
      <li>In case of confirmed non-delivery, carrier typically will find and deliver your package; and</li>
      <li>In case of confirmed non-delivery and your package is not found, we will either refund you or replace your order.</li>
    </ul>
    <h3>SECTION 7 – ACCURACY OF BILLING AND ACCOUNT INFORMATION</h3>
    <p>
      We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per
      person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same
      credit card, and/or orders that use the same billing and/or shipping address. In the event we make a change to or cancel an order, we
      may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We
      reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.
    </p>
    <p>
      You agree to provide current, complete and accurate purchase and account information for all purchases made through this Site. You
      agree to promptly update your account and other information, including your email address and credit card numbers and expiration
      dates, so that we can complete your transactions and contact you as needed.
    </p>
    <p>
      Upon completion of your order we assume your address to be verified, correct and double checked. However, if it is discovered that you
      provided an incorrect or incomplete shipping address, we will endeavor to contact you within 24-48 hours of purchase via email or
      phone to verify the address you provided or to request a new address to which your orders can be delivered. If within seven (7) days
      after our initial attempt to contact you, we are unable to communicate with you or if you fail to provide us with a complete valid
      address for delivery, we shall store your orders and await further instructions from you.
    </p>
    <p>
      Under no circumstance will we be liable for any cost, loss, depreciation, or damage, arising from delay in delivery or non-delivery
      due to the incorrect or incomplete shipping address details provided by you.
    </p>
    <p>For more details, please review our Returns Policy.</p>
    <h3>SECTION 8 – THIRD-PARTY LINKS</h3>
    <p>Certain content, products and services available via our Service may include materials from third-parties.</p>
    <p>
      Third-party links on this Site may direct you to third-party services or websites that are not affiliated with us. We are not
      responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or
      responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
    </p>
    <p>
      We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other
      transactions made in connection to any third-party websites. Please review carefully the third-party’s policies and practices and make
      sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party
      products should be directed to the third-party.
    </p>
    <h3>SECTION 9 – USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h3>
    <p>
      If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative
      ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively,
      ‘comments’), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in
      any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence;
      (2) to pay compensation for any comments; or (3) to respond to any comments.
    </p>
    <p>
      We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive,
      threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or
      these Terms of Service.
    </p>
    <p>
      You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or
      other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or
      obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any
      related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or
      third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no
      responsibility and assume no liability for any comments posted by you or any third-party.
    </p>
    <h3>SECTION 10- INTELLECTUAL PROPERTY</h3>
    <p>
      The Site contains content owned or licensed by Company (the “Content”). The Content is protected by copyright, trademark, patent,
      trade secret and other laws and Company owns and retains all rights in the Content and the Service. You will not remove, alter or
      conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Content and you
      will not reproduce, modify, adapt, prepare derivative works based on, perform, display, publish, distribute, transmit, broadcast,
      sell, license or otherwise exploit the Content.
    </p>
    <p>
      The Company name and logo are trademarks of Company, and may not be copied, imitated or used, in whole or in part, without prior
      written permission. In addition, all page headers, custom graphics, and scripts are service marks, trademarks and/or trade dress of
      Company, and may not be copied, imitated or used, in whole or in part, without prior written permission from Company.
    </p>
    <h3>SECTION 11- ELECTRONIC COMMUNICATIONS</h3>
    <p>
      By accepting this Agreement and using our Site and Service, you consent to receive communications from us electronically. Although we
      may choose to communicate with you by other means, we may also choose to solely communicate with you electronically by e-mail or by
      posting notices on the Site. You agree that all agreements, notices, disclosures, and other communications that we send to you
      electronically satisfy any legal requirement that such communications be in writing.
    </p>
    <p>
      By providing your mobile phone number to us or our Network, you consent to receive telephone calls, including artificial voice calls,
      pre-recorded messages and/or calls delivered via automated technology and text and SMS messages to the telephone number(s) that you
      provided from us listed in and hyperlinked to the consent. Message Frequency Varies. The mobile carriers are not liable for delayed or
      undelivered Messages. Consumers may request up to a maximum of twenty (20) messages per month, with no more than three (3) text
      messages in one day. Your express consent to receive telephone calls and SMS messages is not a condition of purchasing and availing
      our products and services.
    </p>
    <p>
      You understand that the text messages we send may be seen by anyone with access to your phone. Accordingly, you should take steps to
      safeguard your phone and your text messages if you want them to remain private.
    </p>
    <p>
      If you wish to stop receiving text messages from us, reply to any text message we have sent you and simply text “STOP”, “END” or
      “QUIT”. You may also request to stop receiving text messages by calling us or emailing us using the following information:
    </p>
    <p>
      By email:&nbsp;<a href="mailto:miraclesmileadvanced@rephelpdesk.com.">miraclesmileadvanced@rephelpdesk.com.</a>
    </p>
    <p>Official Store</p>
    <p>
      If at any time you need our contact information or information on how to stop text messages, reply to any text message we have sent
      you and simply text HELP or click here for support. Message and Data Rates May Apply to any text/SMS communication.
    </p>
    <h3>SECTION 12 – PERSONAL INFORMATION</h3>
    <p>
      Your submission of personal information through the store is governed by our Privacy Policy. For more detail, please review our
      privacy policy.
    </p>
    <h3>SECTION 13 – ERRORS, INACCURACIES AND OMISSIONS</h3>
    <p>
      Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that
      may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve
      the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in
      the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).
    </p>
    <p>
      We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without
      limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any
      related website, should be taken to indicate that all information in the Service or on any related website has been modified or
      updated.
    </p>
    <h3>SECTION 14 – PROHIBITED USES</h3>
    <p>
      In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for
      any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal,
      provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or
      the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or
      discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false
      or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way
      that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to
      collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene
      or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other
      websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the
      prohibited uses.
    </p>
    <h3>SECTION 15 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h3>
    <p>
      We do not guarantee, represent or warrant that your use of our site or service will be uninterrupted, timely, secure, or error-free.
    </p>
    <p>We do not warrant that the results that may be obtained from the use of the site or service will be accurate or reliable.</p>
    <p>
      You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without
      notice to you.
    </p>
    <p>
      You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services
      delivered to you through the service are (except as expressly stated by us) provided “as is” and “as available” for your use, without
      any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of
      merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
    </p>
    <p>
      In no case shall the Company, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service
      providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential
      damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any
      similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any
      of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any
      product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result
      of the use of the service or any content or product posted, transmitted, or otherwise made available via the service, even if advised
      of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential
      or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
    </p>
    <h3>SECTION 16 – INDEMNIFICATION</h3>
    <p>
      You agree to indemnify, defend and hold harmless the Company and our parent, subsidiaries, affiliates, partners, officers, directors,
      agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or
      demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service
      or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
    </p>
    <h3>SECTION 17 – SEVERABILITY</h3>
    <p>
      In the event any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall
      nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be
      severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining
      provisions.
    </p>
    <h3>SECTION 18 – TERMINATION</h3>
    <p>
      The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement
      for all purposes.
    </p>
    <p>
      These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any
      time by notifying us that you no longer wish to use our Services, or when you cease using our Site.
    </p>
    <p>
      If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service,
      we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the
      date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
    </p>
    <h3>SECTION 19 – ENTIRE AGREEMENT</h3>
    <p>
      The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right
      or provision.
    </p>
    <p>
      These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitutes the
      entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous
      agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior
      versions of the Terms of Service).
    </p>
    <h3>SECTION 20 – GOVERNING LAW</h3>
    <p>
      These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance
      with the laws of the province of British Columbia and you consent to the exclusive jurisdiction of the courts of British Columbia.
    </p>
    <h3>SECTION 21 – CHANGES TO TERMS OF SERVICE</h3>
    <p>You can review the most current version of the Terms of Service at any time on this page.</p>
    <p>
      We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and
      changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to
      our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
    </p>
    <h3>SECTION 22 – CONTACT INFORMATION</h3>
    <p>Questions about the Terms of Service should be sent to us at &nbsp;miraclesmileadvanced@rephelpdesk.com.</p>
    <p>RETURNS AND EXCHANGE POLICY</p>
    <p>
      Our Return Policy lasts 60 days and it takes effect from the time you receive your purchase. If more than 60 days have gone by since
      delivery of your purchase, unfortunately we can’t offer you a refund, exchange or price equivalent in store credit.
    </p>
    <p>Assuming you return your purchase within 60 days from receipt, to be eligible for a return:</p>
    <ol>
      <li>Product must be in brand new condition, unused/unworn and it its original packaging.</li>
      <li>Product packaging must be intact and returned to us in an appropriate shipping container.</li>
      <li>Product must be returned to the return facility address provided by our customer service.</li>
    </ol>
    <p>Non-returnable items:</p>
    <ul>
      <li>Gift cards</li>
      <li>Downloadable software products</li>
      <li>Product is returned damaged</li>
    </ul>
    <p>
      For reasons of health protection, sanitary and hygiene, unless they are faulty, we cannot offer refunds on our products unless they
      are returned within 60 days from the time you received your purchase, new, unused/unworn, and intact in their original packaging. Due
      to health protection or hygiene reasons, return of our product is not suitable after it has been unsealed or the packaging opened
      because resale or reuse of our product is impossible.
    </p>
    <p>
      To ensure the quick processing of your return please confirm the return facility address with our customer service at
      miraclesmileadvanced@rephelpdesk.com.&nbsp;and provide us with the tracking number. All products must be returned to the return
      facility address confirmed by our customer service agent, otherwise we may reject your return. Once your return is received and
      inspected, we will send you an email to notify you that we have received your returned item. Return will be processed within 60 days
      upon receipt of the returned item at the returns facility. Once the return has been processed, we will also notify you of the approval
      or rejection of your return.
    </p>
    <p>
      Approved returns may be refunded or exchanged. If you need to exchange your purchase for the same item or you want to request for a
      refund send us an email at&nbsp;miraclesmileadvanced@rephelpdesk.com. Approved refund will be issued to the same payment method used
      for the order. For orders made by credit card, the amount to be refunded will be visible on the next bank statement of the card. The
      time of processing may vary depending on the issuing company of the credit card, this time generally takes up to 45 working days.
      Refund of orders made by bank transfer will be completed on the original bank account within 48 hours from issuance of credit note and
      only after receiving the bank details from customers. Refund is made on the same currency of the original order. Possible difference
      in the refunded amount may depend on variations of the currency exchange rate and will not be refunded.
    </p>
    <p>
      If you are a customer in the European Economic Area (EEA), and you prefer a refund and your request has been approved for being
      compliant with our Return Policy requirements, you must return such item to us and we must receive it before the expiration of the
      14th day from notifying us that you would like to cancel your order. Once approved and provided we receive the returned item before
      the expiration of said 14th day, we shall reimburse all payments received from you, including the costs of delivery, without undue
      delay and in any event not later than 14 days of notifying us that you would like to cancel your order, subject to refund processing
      periods provided in the previous section
    </p>
    <h4>Shipping</h4>
    <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.</p>
    <p>
      For replacement , there will be a shipping and handling fee of US$10 per item or higher, depending on where you live and other
      factors. Depending on where you live, the time it may take for your exchanged product to reach you, may vary.
    </p>
    <p>
      If you are shipping an item over $75, you should consider using a trackable shipping service or purchasing shipping insurance. We
      don’t guarantee that we will receive your returned item.
    </p>
  </div>
);

export default TermsOfService;
