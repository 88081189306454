import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductTag from './product-tag';
import ProductTagDetail from './product-tag-detail';
import ProductTagUpdate from './product-tag-update';
import ProductTagDeleteDialog from './product-tag-delete-dialog';

const ProductTagRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductTag />} />
    <Route path="new" element={<ProductTagUpdate />} />
    <Route path=":id">
      <Route index element={<ProductTagDetail />} />
      <Route path="edit" element={<ProductTagUpdate />} />
      <Route path="delete" element={<ProductTagDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductTagRoutes;
