import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';

const Footer = () => (
  <footer id="footer" className="pt-5 pb-2">
    <div className="container">
      <div className="row justify-centent-between ">
        <div className="col">
          <div className="footer-logo text-center text-md-start">
            <img src="content/images/white-logo.png" />
          </div>
          {/* <div className="social-icon mt-4">
            <ul className="d-flex gap-3 p-0 list-unstyled">
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path
                    d="M16.5 6.28125H13.5C12.9477 6.28125 12.5 6.72897 12.5 7.28125V10.2812H16.5C16.6137 10.2787 16.7216 10.3317 16.7892 10.4232C16.8568 10.5146 16.8758 10.6333 16.84 10.7413L16.1 12.9412C16.0318 13.1432 15.8431 13.2796 15.63 13.2812H12.5V20.7812C12.5 21.0574 12.2761 21.2812 12 21.2812H9.5C9.22386 21.2812 9 21.0574 9 20.7812V13.2812H7.5C7.22386 13.2812 7 13.0574 7 12.7812V10.7812C7 10.5051 7.22386 10.2812 7.5 10.2812H9V7.28125C9 5.07211 10.7909 3.28125 13 3.28125H16.5C16.7761 3.28125 17 3.50511 17 3.78125V5.78125C17 6.05739 16.7761 6.28125 16.5 6.28125Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16 3.28125H8C5.23858 3.28125 3 5.51983 3 8.28125V16.2812C3 19.0427 5.23858 21.2812 8 21.2812H16C18.7614 21.2812 21 19.0427 21 16.2812V8.28125C21 5.51983 18.7614 3.28125 16 3.28125ZM19.25 16.2812C19.2445 18.0738 17.7926 19.5257 16 19.5312H8C6.20735 19.5257 4.75549 18.0738 4.75 16.2812V8.28125C4.75549 6.4886 6.20735 5.03674 8 5.03125H16C17.7926 5.03674 19.2445 6.4886 19.25 8.28125V16.2812ZM16.75 8.53125C17.3023 8.53125 17.75 8.08353 17.75 7.53125C17.75 6.97897 17.3023 6.53125 16.75 6.53125C16.1977 6.53125 15.75 6.97897 15.75 7.53125C15.75 8.08353 16.1977 8.53125 16.75 8.53125ZM12 7.78125C9.51472 7.78125 7.5 9.79597 7.5 12.2812C7.5 14.7666 9.51472 16.7812 12 16.7812C14.4853 16.7812 16.5 14.7666 16.5 12.2812C16.5027 11.0869 16.0294 9.94082 15.1849 9.09633C14.3404 8.25184 13.1943 7.77859 12 7.78125ZM9.25 12.2812C9.25 13.8 10.4812 15.0312 12 15.0312C13.5188 15.0312 14.75 13.8 14.75 12.2812C14.75 10.7625 13.5188 9.53125 12 9.53125C10.4812 9.53125 9.25 10.7625 9.25 12.2812Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path
                    d="M20.9728 6.99865C20.5084 7.61817 19.947 8.15858 19.3103 8.59901C19.3103 8.76084 19.3103 8.92267 19.3103 9.0935C19.3154 12.0324 18.1415 14.8504 16.0518 16.9158C13.962 18.9812 11.1312 20.1212 8.19405 20.0802C6.49599 20.0859 4.81967 19.6982 3.29642 18.9474C3.21428 18.9115 3.16131 18.8303 3.16162 18.7406V18.6417C3.16162 18.5126 3.26623 18.408 3.39527 18.408C5.06442 18.3529 6.67402 17.7742 7.99634 16.7537C6.48553 16.7232 5.12619 15.8282 4.5006 14.452C4.46901 14.3769 4.47884 14.2906 4.52657 14.2245C4.57429 14.1584 4.653 14.122 4.73425 14.1284C5.19342 14.1745 5.65718 14.1318 6.1002 14.0025C4.43239 13.6563 3.17921 12.2717 2.99986 10.577C2.99349 10.4957 3.02992 10.417 3.096 10.3692C3.16207 10.3215 3.24824 10.3116 3.32338 10.3433C3.77094 10.5408 4.25409 10.6448 4.74324 10.6489C3.28184 9.68971 2.65061 7.8653 3.20655 6.20747C3.26394 6.04638 3.40181 5.92737 3.5695 5.89419C3.73718 5.861 3.90996 5.91853 4.02432 6.04564C5.99639 8.1445 8.70604 9.39521 11.5819 9.53404C11.5083 9.2401 11.4721 8.93801 11.4741 8.63497C11.501 7.04597 12.4842 5.63046 13.9634 5.05112C15.4425 4.47179 17.1249 4.84328 18.223 5.99169C18.9714 5.8491 19.695 5.5977 20.3707 5.24546C20.4202 5.21456 20.483 5.21456 20.5325 5.24546C20.5634 5.29498 20.5634 5.35777 20.5325 5.40729C20.2052 6.15677 19.6523 6.78537 18.9509 7.20544C19.5651 7.13421 20.1685 6.98932 20.7482 6.77389C20.797 6.74067 20.8611 6.74067 20.9099 6.77389C20.9508 6.79259 20.9814 6.82836 20.9935 6.87167C21.0056 6.91498 20.998 6.96143 20.9728 6.99865Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5 3.28125H19C20.1046 3.28125 21 4.17668 21 5.28125V19.2812C21 20.3858 20.1046 21.2812 19 21.2812H5C3.89543 21.2812 3 20.3858 3 19.2812V5.28125C3 4.17668 3.89543 3.28125 5 3.28125ZM8 18.2812C8.27614 18.2812 8.5 18.0574 8.5 17.7812V10.7812C8.5 10.5051 8.27614 10.2812 8 10.2812H6.5C6.22386 10.2812 6 10.5051 6 10.7812V17.7812C6 18.0574 6.22386 18.2812 6.5 18.2812H8ZM7.25 9.28125C6.42157 9.28125 5.75 8.60968 5.75 7.78125C5.75 6.95282 6.42157 6.28125 7.25 6.28125C8.07843 6.28125 8.75 6.95282 8.75 7.78125C8.75 8.60968 8.07843 9.28125 7.25 9.28125ZM17.5 18.2812C17.7761 18.2812 18 18.0574 18 17.7812V13.1812C18.0325 11.592 16.8576 10.2358 15.28 10.0413C14.177 9.9405 13.1083 10.4557 12.5 11.3813V10.7812C12.5 10.5051 12.2761 10.2812 12 10.2812H10.5C10.2239 10.2812 10 10.5051 10 10.7812V17.7812C10 18.0574 10.2239 18.2812 10.5 18.2812H12C12.2761 18.2812 12.5 18.0574 12.5 17.7812V14.0312C12.5 13.2029 13.1716 12.5312 14 12.5312C14.8284 12.5312 15.5 13.2029 15.5 14.0312V17.7812C15.5 18.0574 15.7239 18.2812 16 18.2812H17.5Z"
                    fill="white"
                  />
                </svg>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          {/* <form className="newsletter-form">
            <label className="form-label text-white fs-6">Subscribe</label>
            <div className="form-c d-flex gap-3">
              <input type="text" id="disabledTextInput" className="form-control rounded-0" placeholder="Enter your email" />
              <button type="submit" className="btn newslter-btn">
                Subscribe
              </button>
            </div>
          </form>
          <p className="text-white news-paragraph my-3">By subscribing you agree to our Privacy Policy</p> */}
        </div>
      </div>
      <div className="row justify-centent-between border-top pt-3 mt-3">
        <div className="col">
          <ul className="footer-menu d-flex gap-3 mb-0 p-0 list-unstyled justify-content-center justify-content-md-start">
            <li>
              <Link to="/privacy-policy" className="text-white ">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/terms-of-service" className="text-white ">
                Terms of Service
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className="text-white ">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <p className="text-white text-center text-md-end mb-0 copyright m-md-0 mt-3"> ©2023, All right reserved.</p>
        </div>
        <p className="text-white news-paragraph mt-4 text-center mb-0">
          Distributed by Ontel Products Corp., Fairfield, NJ 07004, under license from IMG Int’l, Ltd., Scarsdale, NY 10583. Miracle Smile®
          is a registered trademark of Spa Dent, Inc., Waterloo Canada N2K3T6, used under license.
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
