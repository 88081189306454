export interface ADDProduct {
  productId?: number;
  quantity?: number | null;
  price?: number | null;
  discountCodes?: number | null;
  discountPercentage?: string | null;
  active?: boolean | null;
}

export const defaultValue: Readonly<ADDProduct> = {
  active: false,
};
