import { IPayment } from 'app/shared/model/payment.model';
import { IAddress } from 'app/shared/model/address.model';
import { IProductInCart } from 'app/shared/model/product-in-cart.model';
import { ICart } from 'app/shared/model/cart.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';

export interface IOrder {
  id?: number;
  totalAmount?: number | null;
  status?: OrderStatus | null;
  taxAmount?: number | null;
  orderNumber?: string | null;
  productInCarts?: IProductInCart[] | null;
  payments?: IPayment[] | null;
  addresses?: IAddress[] | null;
  orders?: IOrder[] | null;
  cart?: ICart | null;
  orderParent?: IOrder | null;
}

export const defaultValue: Readonly<IOrder> = {};
