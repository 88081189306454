export interface ISalesReport {
  id?: number | null;
  name?: string | null;
  sku?: string | null;
  unit_price?: number | null;
  sale_quantity?: number | null;
  total?: number | null;
}

export const defaultValue: Readonly<ISalesReport> = {};
