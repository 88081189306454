import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'app/config/store';
import './footer.scss';
import { createTicket } from './contactpage/contact-reducers';
import { Alert } from 'reactstrap';

const Contact = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({});
  const [submitted, setSubmitted] = useState<any>();
  const [orderId, setOrderId] = useState(0);
  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    let data = {
      body_text: `${formData?.body_text} | Name: ${formData?.firstname} ${formData?.lastname} | Phone: ${formData?.phone}`,
      email: formData.email,
    };
    let order = await dispatch(createTicket(data));
    if (order?.payload) {
      setSubmitted(true);
      setOrderId(order?.payload['data']?.id);
      setFormData({});
    }
    if (order['error']) {
      setSubmitted('error');
    }
  };

  const handleInputChange = event => {
    setSubmitted(false);
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onDismiss = () => setSubmitted(false);

  return (
    <div className="container" style={{ maxWidth: '1440px', padding: '40px' }}>
      <div className="bg-light">
        <div className="row">
          <div className="col-lg-8 col-md-12 pe-5 bg-white rounded-3">
            <form onSubmit={e => handleSubmit(e)} className="row mb-3">
              <div className="col-md-6 p-3">
                <input
                  onChange={handleInputChange}
                  required
                  autoComplete="off"
                  placeholder="First Name"
                  type="text"
                  name="firstname"
                  id=""
                />
              </div>
              <div className="col-md-6 p-3">
                <input required onChange={handleInputChange} autoComplete="off" placeholder="Last Name" type="text" name="lastname" id="" />
              </div>
              <div className="col-md-6 p-3">
                <input required onChange={handleInputChange} autoComplete="off" placeholder="E-mail" type="email" name="email" id="" />
              </div>
              <div className="col-md-6 p-3">
                <input required onChange={handleInputChange} autoComplete="off" placeholder="Phone" type="tel" name="phone" id="" />
              </div>
              <div className="col-md">
                <textarea
                  required
                  onChange={handleInputChange}
                  autoComplete="off"
                  name="body_text"
                  placeholder="Write your message"
                  id=""
                  cols={30}
                  rows={1}
                ></textarea>
              </div>
              <div className="text-end mt-4">
                <button className="btn btn-primary content-btn" type="submit" value="Send Message">
                  Send Message{' '}
                </button>
              </div>
            </form>
            {submitted == true && (
              <Alert toggle={onDismiss}>
                Ticket submitted successfully! Your ticket #<span className="order-id">{orderId}</span>
              </Alert>
            )}
            {submitted == 'error' && (
              <Alert color="warning" toggle={onDismiss}>
                Ticket against the email already exists.
              </Alert>
            )}
          </div>
          <div className="col-lg-4 col-md-12 text-white aside px-4 py-5">
            <div className="mb-5">
              <h1 className="h3">Contact Information</h1>
              <p className="opacity-50">
                <small>Please email at the address below and we will get back to you within 23 hours</small>
              </p>
            </div>
            <div className="d-flex flex-column px-0">
              <ul className="m-0 p-0">
                <li className="d-flex align-items-center r mb-4">
                  <span>
                    <a style={{ color: 'white' }} href="mailto:miraclesmileadvanced@rephelpdesk.com">
                      miraclesmileadvanced@rephelpdesk.com
                    </a>
                  </span>
                </li>
                <li className="d-flex justify-content-start align-items-center mb-4">
                  <span>
                    21 Law Drive, Fairfield, <br />
                    NJ 07004
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
