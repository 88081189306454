import { getEntities as ProductInCartEntities } from 'app/entities/product-in-cart/product-in-cart.reducer';

import { getEntities } from './salesReport.reducer';
import { getEntities as downloadReport } from './downloadReport.reducer';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Table, Button } from 'reactstrap';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {}
export const OrderListView = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const [dataList, setDataList] = useState([]);
  const salesReport = useAppSelector(state => state.salesReport.entities);
  const loading = useAppSelector(state => state.salesReport.loading);
  const totalItems = useAppSelector(state => state.salesReport.totalItems);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const entityFields = ['SKU', 'Product Name', 'Quantity', 'Unit Price', 'Total'];

  const formatDate = date => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  };

  const relationshipDataAdd = () => {
    let merged = salesReport;

    if (merged.length > 0) {
      merged = merged.map(result =>
        entityFields.reduce((obj, key) => {
          if (key == 'SKU') {
            obj[key] = result?.sku;
          } else if (key == 'Product Name') {
            obj[key] = result?.name;
          } else if (key == 'Quantity') {
            obj[key] = result?.sale_quantity;
          } else if (key == 'Unit Price') {
            obj[key] = result?.unit_price;
          } else if (key == 'Total') {
            let totalInDecimal = result?.total?.toFixed(2);
            obj[key] = totalInDecimal;
          } else {
            obj[key] = result[key] || '';
          }

          return obj;
        }, {})
      );
    }
    setDataList(merged);
  };

  useEffect(() => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  }, [paginationState.activePage, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  useEffect(() => {
    relationshipDataAdd();
  }, [salesReport]);

  const handleDatePicker = (date, type) => {
    const currentDate = new Date();
    let newStartDate = startDate;
    let newEndDate = endDate;

    if (date <= currentDate) {
      if (type === 'start') {
        setStartDate(date);
        newStartDate = date;
      } else if (type === 'end') {
        setEndDate(date);
        newEndDate = date;
      }
    }

    const formattedStartDate = formatDate(newStartDate);
    const formattedEndDate = formatDate(newEndDate);
    if (newStartDate && newEndDate) {
      dispatch(getEntities({ fromDate: formattedStartDate, toDate: formattedEndDate }));
    }
  };
  const handleDateClear = () => {
    if (startDate || endDate) {
      setStartDate('');
      setEndDate('');
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
  };
  const downloadReports = async () => {
    let formattedStartDate = formatDate(startDate);
    let formattedEndDate = formatDate(endDate);
    let downloadReportParams = {
      reportType: 'sales',
      fromDate: '',
      toDate: '',
    };
    if (startDate && endDate) {
      downloadReportParams = { ...downloadReportParams, fromDate: formattedStartDate, toDate: formattedEndDate };
    }
    const { payload } = await dispatch(downloadReport(downloadReportParams));
    let blob = new Blob([payload['data']], { type: 'text/csv' });

    const a = document.createElement('a');
    a.download = 'salesReport';
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <div className="table-responsive mt-4 card mx-5" style={{ height: !(dataList.length > 7) && 'calc(100vh - 475px' }}>
      {loading && (
        <div className="loader">
          <img src="content/images/loader.gif" />
        </div>
      )}
      <div className="date-picker-header">
        <h5 className="text-capitalize mb-3">Filter By Date: </h5>
        <div className="date-picker">
          <h5>From:</h5>
          <DatePicker
            selected={startDate}
            onChange={date => handleDatePicker(date, 'start')}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          <h5>To:</h5>
          <DatePicker
            selected={endDate}
            onChange={date => handleDatePicker(date, 'end')}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>

        <Button
          color="info"
          className="download-report ms-2"
          size="m"
          data-cy="entityEditButton"
          onClick={downloadReports}
          disabled={!(dataList.length > 0)}
        >
          <span className="d-none d-md-inline">Download Report</span>
        </Button>
        <Button color="primary" size="m" data-cy="entityEditButton" className="ms-2" onClick={handleDateClear}>
          <span className="d-none d-md-inline ">Clear</span>
        </Button>
      </div>

      {dataList.length > 0 ? (
        <Table
          hover
          className="align-baseline table table-group-divider"
          style={{ borderLeft: '1px solid #ddd', borderRight: '1px solid #ddd' }}
        >
          {dataList.length > 0 && (
            <>
              <thead className="bg-opacity-10 bg-primary shadow-sm text-capitalize">
                <tr>
                  {Object.keys(dataList[0])
                    .filter(items => entityFields.includes(items))
                    .map(item => {
                      return (
                        <th style={{ padding: '10px' }} className="fw-semibold">
                          {item}
                        </th>
                      );
                    })}
                  {/* <th style={{ padding: '10px' }} className="width-min fw-semibold">
                    Action
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {dataList.map((product, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    {Object.entries(product).map(([key, value]) => {
                      if (entityFields.includes(key)) {
                        return <td>{value as string} </td>;
                      }
                    })}
                    {/* <td className="width-min text-nowrap">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/order/detail/${product.id}`}
                          outline
                          color="success"
                          className="me-2 rounded-5"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon className="me-1" icon="eye" /> <span className="d-none d-md-inline">View</span>
                        </Button>
                        <Button
                          tag={Link}
                          to={`/order/${product.id}`}
                          color="dark"
                          className="rounded-5"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon className="me-1" icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                        </Button>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </Table>
      ) : (
        !loading && <div className="alert alert-warning">No data found</div>
      )}
      {totalItems ? (
        <div className={dataList && dataList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OrderListView;
