export interface Tax {
  amount?: number;
  shipping?: number;
  to_country?: string;
  to_zip?: string;
  to_state?: string;
  to_city?: string;
  to_street?: string;
}

export const defaultValue: Readonly<Tax> = {};
