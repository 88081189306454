import ProductListView from 'app/override/modules/home-page/ProductListView';

import ProductcategoryListView from './ProductcategoryListView';

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
interface Props {}

export const HomePage = (props: Props) => {
  return (
    <Row>
      <Col md="12" className="mx-5"></Col>

      <Col md="12">
        <ProductListView />
      </Col>

      <Col md="12">{/* <ProductcategoryListView /> */}</Col>

      <span id="custom-components" />
    </Row>
  );
};

export default HomePage;
