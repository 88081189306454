import { IProduct } from 'app/shared/model/product.model';
import { ITag } from 'app/shared/model/tag.model';

export interface IProductTag {
  id?: number;
  product?: IProduct | null;
  tag?: ITag | null;
}

export const defaultValue: Readonly<IProductTag> = {};
