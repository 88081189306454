import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Nav, NavItem, NavLink, Table } from 'reactstrap';
import { Translate, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IOrder } from 'app/shared/model/order.model';
import { getEntities } from './order.reducer';
import OrderCancelDialog from './order-cancel-dialog';

export const OrderActiveView = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const orderList = useAppSelector(state => state.order.entities);
  const updateSuccess = useAppSelector(state => state.order.updateSuccess);
  const loading = useAppSelector(state => state.order.loading);
  const totalItems = useAppSelector(state => state.order.totalItems);
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [showTab, setShowTab] = useState('Active');

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: `status.notIn=${['SHIPPED', 'CANCELLED']}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const cancelOrder = orderId => {
    setshowCancelModal(true);
    setOrderId(orderId);
  };
  const viewModal = modal => {
    setshowCancelModal(modal);
    sortEntities();
  };
  const formatDate = created => {
    const getDateDetails = new Date(created);
    const month = getDateDetails.getMonth() + 1;
    const day = getDateDetails.getDate();
    const year = getDateDetails.getFullYear();

    return `${month}-${day}-${year}`;
  };

  return (
    <>
      <div className="table-responsive">
        {orderList && orderList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  Order Number
                  <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('totalAmount')}>
               <Translate contentKey="coreShoppingCartApp.order.totalAmount">Total Amount</Translate> <FontAwesomeIcon icon="sort" />
             </th> */}
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="coreShoppingCartApp.order.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  Order Date <FontAwesomeIcon icon="sort" />
                </th>
                {/* <th className="hand" onClick={sort('taxAmount')}>
               <Translate contentKey="coreShoppingCartApp.order.taxAmount">Tax Amount</Translate> <FontAwesomeIcon icon="sort" />
             </th> */}
                {/* <th>
               <Translate contentKey="coreShoppingCartApp.order.cart">Cart</Translate> <FontAwesomeIcon icon="sort" />
             </th> */}
                <th />
              </tr>
            </thead>
            <tbody>
              {orderList &&
                orderList.map((order, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/order/${order.id}`} color="link" size="sm">
                        {order.orderNumber || order.id}
                      </Button>
                    </td>
                    {/* <td>{order.totalAmount}</td> */}
                    <td>{order.status}</td>
                    <td>{formatDate(order.createdAt)} </td>
                    {/* <td>{order.taxAmount}</td> */}
                    {/* <td>{order.cart ? <Link to={`/cart/${order.cart.id}`}>{order.cart.id}</Link> : ''}</td> */}
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`/order/${order.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        {order.status == 'BACK_ORDERED' || order.status == 'NEW' || order.status == 'CONFIRMED' ? (
                          <Button
                            tag={Link}
                            to={`/order/${order.id}/modify?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            color="primary"
                            size="sm"
                            data-cy="entityModifyButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modify</span>
                          </Button>
                        ) : (
                          ''
                        )}
                        <Button
                          tag={Link}
                          to={`/order/${order.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Manage</span>
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                          onClick={() => {
                            cancelOrder(order.id);
                          }}
                        >
                          <svg width="14" height="14" viewBox="0 0 10 10" fill="none">
                            <path
                              d="M1.73341 0.916341L0.916748 1.73301L4.18342 4.99967L0.916748 8.26634L1.73341 9.08301L5.00008 5.81634L8.26675 9.08301L9.08342 8.26634L5.81675 4.99967L9.08342 1.73301L8.26675 0.916341L5.00008 4.18301L1.73341 0.916341Z"
                              fill="white"
                            />
                          </svg>
                          <span className="d-none d-md-inline">Cancel</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="coreShoppingCartApp.order.home.notFound">No Orders found</Translate>
            </div>
          )
        )}
      </div>

      {totalItems ? (
        <div className={orderList && orderList.length > 0 ? 'order-pagination' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      {showCancelModal && <OrderCancelDialog orderId={orderId} showCancelModal={viewModal} />}
    </>
  );
};

export default OrderActiveView;
