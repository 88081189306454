export interface IDiscountCode {
  id?: number;
  discountCode?: string | null;
  maxProductClaims?: number | null;
  productClaimsCount?: number | null;
  maxCartClaims?: number | null;
  cartClaimsCount?: number | null;
  discountAmount?: number | null;
  discountPercentage?: number | null;
}

export const defaultValue: Readonly<IDiscountCode> = {};
