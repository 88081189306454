import dayjs from 'dayjs';
import { IPayment } from 'app/shared/model/payment.model';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';

export interface IPaymentMethod {
  id?: number;
  expirationDate?: string | null;
  lastUseDate?: string | null;
  vaultToken?: string | null;
  name?: string | null;
  paymentType?: PaymentType | null;
  cvvCode?: string | null;
  otpCode?: string | null;
  billingName?: string | null;
  creditCardNumber?: string | null;
  authorization?: string | null;
  authorizationAmount?: number | null;
  creditCardLast5?: string | null;
  accountIdentifier?: string | null;
  authorizationTimestamp?: string | null;
  payments?: IPayment[] | null;
}

export const defaultValue: Readonly<IPaymentMethod> = {};
