import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { Checkout, defaultValue } from 'app/shared/model/checkout.model';

const initialState: EntityState<any> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ticket';

// Actions

export const createTicket = createAsyncThunk('ticket/create_ticket', async (data: any) => {
  const requestUrl = `${apiUrl}`;
  return axios.post<any[]>(requestUrl, data);
});

// slice

export const ticketSlice = createEntitySlice({
  name: 'ticket',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(createTicket), (state, action) => {
        state.updating = false;
        state.loading = false;
      })
      .addMatcher(isPending(createTicket), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = ticketSlice.actions;

// Reducer
export default ticketSlice.reducer;
