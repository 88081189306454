import './footer.scss';
import React from 'react';

const PrivacyPolicy = () => (
  <div className="container" style={{ maxWidth: '1390px' }}>
    <p className="p1">
      <strong>PRIVACY POLICY</strong>
    </p>
    <p className="p2">
      <strong>
        BY USING OUR SITE, YOU CONSENT TO THE COLLECTION, USE AND TRANSFER OF YOUR PERSONAL DATA FOR PROCESSING AS DESCRIBED IN THIS PRIVACY
        POLICY.
      </strong>
    </p>
    <p className="p3">
      For residents of the European Union: In order to comply with the requirements of the European General Data Protection Regulation
      (GDPR) for our European users, this Privacy Policy outlines the legal basis on which we process your Personal Data and provides other
      information required by the GDPR.
    </p>
    <p className="p2">
      <strong>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</strong>
    </p>
    <p className="p3">We collect your Personal Data in a number of ways and for various purposes, including:</p>
    <p className="p3">
      When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us
      such as your name, address, and email address.
    </p>
    <p className="p3">
      When you browse our Site, we also automatically receive your computer&rsquo;s internet protocol (IP) address in order to provide us
      with information that helps us learn about your browser and operating system.
    </p>
    <p className="p3">
      Email marketing (if applicable and when legally allowed): With your permission and provided it is legal in your jurisdiction, we may,
      within time limits allowed by law, send you emails about our Site, new products and services, and other updates. By
      &ldquo;permission&rdquo; we mean express and provable permission granted by you. A permission to send commercial or marketing emails
      may be through any of the following instances which we deem as existing business relationship with or inquiry from you:
    </p>
    <ul className="ul1">
      <li className="li4">Entering your email address on our Site with no indication that you would not like to be emailed.</li>
      <li className="li4">
        Entering your email address as part of attempting to order or ordering a product or service from us with no indication that you
        would not like to be emailed.
      </li>
      <li className="li4">
        Entering your email address as part of an abandoned cart with no indication that you would not like to be emailed.
      </li>
      <li className="li4">
        Entering your email address as part of participation in any contest, event or survey conducted by us and we have informed you that
        we will be sending you marketing emails.
      </li>
      <li className="li4">Subscribing to an email newsletter by filling-out a form on our Site.</li>
      <li className="li4">
        Any instance where in completing a form, you have checked an opt-in checkbox indicating your willingness to be contacted through
        email provided the checkbox is unchecked by default and we have informed you that the nature of the emails will be commercial.
      </li>
    </ul>
    <p className="p3">
      All marketing emails shall conspicuously contain a notice that at no cost to you, you can opt out of receiving more marketing emails
      from us.
      <br />
      All marketing emails shall prominently display a one-click unsubscribe or opt-out option. Once you have opted out or unsubscribed, we
      shall immediately refrain from sending marketing emails to you.
    </p>
    <p className="p3">
      We may use your personal information, including but not limited to your name, address, telephone, email address and other relevant
      data, to conduct our business, improve our Sites, develop new products and services, provide information and support, to better
      understand your needs and interests, personalize communications and advertising, meet contractual obligations, and generally promote a
      quality experience for you. For example, we may use your personal information, including your email address, to:
    </p>
    <ul className="ul1">
      <li className="li4">Communicate, interact and build our relationship with you;</li>
      <li className="li4">Customize the content, products and services that are offered to you;</li>
      <li className="li4">Contact you with information about us and affiliated third-parties;</li>
      <li className="li4">Process, fulfill and follow up on transactions and requests for products, services, support, and information;</li>
      <li className="li4">Verify your authority to enter and use our services;</li>
      <li className="li4">Engage in market research and analysis;</li>
      <li className="li4">
        Measure, analyze and improve our products and services, the effectiveness of our websites, and our advertising and marketing;
      </li>
      <li className="li4">Comply with legal requirements;</li>
      <li className="li4">Provide targeting advertising, including the use of re-targeting technology;</li>
      <li className="li4">
        Send you marketing materials, for example, via email, including our newsletter, and to notify you about products and services that
        we believe would be of interest to you;
      </li>
      <li className="li4">Deter, detect, and prevent fraud and other prohibited or illegal activities; or</li>
      <li className="li4">
        Inform you regarding new services, releases, upcoming events and changes in our terms and conditions or privacy policy.
      </li>
    </ul>
    <p className="p3">
      You may manage your receipt of marketing and non-transactional emails by clicking on the &ldquo;unsubscribe&rdquo; link located on the
      bottom of our marketing and non-transactional emails. If at any point you choose not to receive our emails or newsletters, you can
      also opt-out or unsubscribe by emailing <span className="s1">miraclesmileadvanced@rephelpdesk.com</span>, by following the opt-out
      instructions in the email or newsletter, or by sending mail to Customer Service Team with address at Ontel Products Corporation, 21
      Law Drive, Fairfield, NJ 07004.
    </p>
    <p className="p3">
      Telemarketing (if applicable and when legally allowed): Regardless of the fact that your telephone and/or cell number may be listed
      with the Federal Do-Not-Call Registry or your local State Do-Not-Call list, by providing us your telephone and/or cell number you are
      providing express written consent to receive future information (including telemarketing) about products and services from us and/or
      our affiliates, and you hereby agree and consent to our contacting you using the information you have provided and will provide to us.
      This means that within the time limits allowed by law, we may contact you by e-mail, phone and/or cell number (including use of
      automated dialing equipment and/or pre-recorded calls), text (SMS) message, social networks or any other means of communication that
      your wireless or other telecommunications device may be capable of receiving (i.e. video, etc.). You further acknowledge that you are
      not required to agree directly or indirectly or enter into an agreement regarding our telemarketing efforts as a condition of
      purchasing any goods or services from us or our affiliates. If you do not want to receive marketing calls, please let us know via
      phone call. You can also opt out by sending email to&nbsp;<span className="s1">miraclesmileadvanced@rephelpdesk.com</span>&nbsp;or by
      sending mail to our Customer Service Team with address at Ontel Products Corporation, 21 Law Drive, Fairfield, NJ 07004
    </p>
    <p className="p3">
      The request to opt-out from the mailing list shall be honored immediately. You may not be able to opt out of all information sharing,
      however, such as information sharing with credit card processors in connection with products or services that you order from us. While
      we offer you some control over marketing communication, certain transactional, relationship, and legally required communications will
      not be affected by the choices you have made about marketing communications.
    </p>
    <p className="p3">
      We reserve the right to release and disclose any personal information relative or provided by you to law enforcement or other
      governmental officials as we, in our sole and absolute discretion, deem necessary to comply with any applicable law or at the request
      any governmental entity or agency.
    </p>
    <p className="p3">
      We assume that all visitors and users of our Site have carefully read this document and agree to its contents. If someone does not
      agree with this Privacy Policy, they should refrain from using our Site. We reserve the right to change our Privacy Policy as
      necessity dictates.
    </p>
    <p className="p3">
      Any information stored in our Site is treated as confidential. All information is stored securely and is accessed by authorized
      personnel only. We implement and maintain appropriate technical, security and organizational measures to protect Personal Data against
      unauthorized or unlawful processing and use, and against accidental loss, destruction, damage, theft or disclosure.
    </p>
    <p className="p2">
      <strong>SECTION 2 - GENERAL CONDITIONS</strong>
    </p>
    <p className="p3">How do you get my consent?</p>
    <p className="p3">
      When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a
      delivery or return a purchase, you consent to our collecting your personal information and using it for that specific reason only.
    </p>
    <p className="p3">
      If we ask for your personal information for a secondary reason, we will either ask you directly for your expressed consent, or provide
      you with an opportunity to say no.
    </p>
    <p className="p3">How do I withdraw my consent?</p>
    <p className="p3">
      If after you opt-in, you change your mind, you may withdraw your consent for us to contact you with future effect and without
      affecting the lawfulness of processing of your Personal Data based on the consent you provided before you withdrew it, at any time, by
      contacting us at email address&nbsp;<span className="s1">miraclesmileadvanced@rephelpdesk.com</span>, by following the opt-out
      instructions in the email or newsletter, or by sending mail to Customer Service Team with address at Ontel Products Corporation, 21
      Law Drive, Fairfield, NJ 07004. You may not be able to opt out of all information sharing, however, such as information sharing with
      credit card processors in connection with products or services that you order from us. Certain transactional, relationship, and
      legally required communications will not be affected by the choices you have made about information sharing. Depending on the service,
      collection and use of your Personal Data may be required for the services to work.
    </p>
    <p className="p3">
      We collect and process your Personal Data for a variety of purposes outlined in this Privacy Policy. In certain cases, separate
      consent is not required, including:
    </p>
    <ul className="ul1">
      <li className="li4">For the performance of our contractual obligations to you;</li>
      <li className="li4">
        To meet legal obligations or compliance with laws, regulations, court orders, or other legal obligations or to assist in an
        investigation; or
      </li>
      <li className="li4">
        For Legitimate Interests or to operate our business and provide the services, other than in performing our contractual obligations
        to you for our company&rsquo;s "legitimate interests" for the purposes of applicable law - except where overridden by the interests
        or fundamental rights and freedoms that require protection of Personal Data.
      </li>
    </ul>
    <p className="p3">
      You can, at any time, request to edit, update, access or delete your information by emailing us at&nbsp;
      <span className="s1">miraclesmileadvanced@rephelpdesk.com</span>. We shall, promptly following receipt of notice, delete Personal Data
      from our records and, upon completion of all transactions, comply with all reasonable instructions with respect to the deletion of any
      remaining Personal Data. We will not retain data longer than is necessary to fulfill the purposes for which it was collected or as
      required by applicable laws or regulations. You may request for a copy of your Personal Data, free of charge and in an electronic
      format. You may request for all Personal Data to be provided in a commonly used and machine-readable format and once obtained, you
      have the right to take the same to another company.
    </p>
    <p className="p2">
      <strong>SECTION 3 - DISCLOSURE</strong>
    </p>
    <p className="p3">
      We may disclose your Personal Data for the purposes as described in the prior sections of this Privacy Policy, and in the following
      ways:
    </p>
    <ul className="ul1">
      <li className="li4">
        To Affiliates and Partners: With companies or ventures that are owned or controlled by us, and internally within our company, in
        order to provide and improve services, for marketing purposes, and for advertising.
      </li>
      <li className="li4">
        To Service Providers and Vendors: With business partners, marketing partners, and vendors to provide, improve, and personalize our
        services.
      </li>
      <li className="li4">
        For Advertising and Marketing: With advertising and marketing partners for advertising and marketing purposes, with your consent, on
        our behalf and on behalf of third parties, including social network providers, if any.
      </li>
      <li className="li4">
        For Certain Analytics and Improvement: With certain companies for purposes of analytics and improvement of our services.
      </li>
      <li className="li4">
        For Legal Compliance, Law Enforcement, and Public Safety Purposes: With law enforcement, government or regulatory bodies, lawful
        authorities, or other authorized third parties in order to comply with laws, regulations, court orders, or other legal obligations
        or to assist in an investigation, to protect and defend our rights and property, or the rights or safety of third parties, to
        enforce our Terms of Use, this Privacy Policy, or agreements with third parties, or for crime-prevention purposes.
      </li>
    </ul>
    <p className="p2">
      <strong>SECTION 4 - THIRD-PARTY SERVICES</strong>
    </p>
    <p className="p3">
      In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow
      them to perform certain services they provide to us. Certain third-party services, such as payment gateways and other transaction or
      payment processors are required to abide by security standards imposed on them, such as the Payment Card Industry Data Security
      Standard (PCI-DSS), which is a set of security standards designed to ensure that all payment processors that accept, process, store or
      transmit credit card information maintain a secure environment. All direct payment gateways we utilize adhere to PCI-DSS, which is a
      joint effort of brands like Visa, MasterCard, American Express and Discover, to safeguard card data handling.
    </p>
    <p className="p3">
      Whilst we shall not store your credit card information, such payment gateways and other transaction or payment processors, under
      PCI-DSS, may store your purchase transaction data for only as long as is necessary to complete the transaction and thereafter for only
      as long as it is required by law.
    </p>
    <p className="p3">
      Such third-party payment gateways and other payment transaction processors and other third parties have their own privacy policies in
      respect to the information we are required to provide to them for your purchase-related transactions.
    </p>
    <p className="p3">
      For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal
      information will be handled by these providers.
    </p>
    <p className="p3">
      In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than
      either you or us. So, if you elect to proceed with a transaction that involves the services of a third-party service provider, then
      your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.
    </p>
    <p className="p3">
      Once you leave our Site or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy
      or our website&rsquo;s Terms of Service.
    </p>
    <p className="p2">
      <strong>SECTION 5 &ndash; ELECTRONIC COMMUNICATIONS, TELEMARKETING AND TEXT MESSAGES</strong>
    </p>
    <p className="p3">
      By providing your prior express consent/opt-in in accordance with applicable law, you consent to receive communications from us
      electronically. Although we may choose to communicate with you by other means, we may also choose to solely communicate with you
      electronically by e-mail or by posting notices on the Site. You agree that all agreements, notices, disclosures, and other
      communications that we send to you electronically satisfy any legal requirement that such communications be in writing.
    </p>
    <p className="p3">
      Where you provide &ldquo;prior express written consent&rdquo; within the meaning of the Telephone Consumer Protection Act
      (&ldquo;TCPA&rdquo;) or any applicable law, you consent to receive telephone calls, including artificial voice calls, pre-recorded
      messages and/or calls delivered via automated technology and text and SMS messages to the telephone number(s) that you provided from
      us listed in and hyperlinked to the consent. Message Frequency Varies. The mobile carriers are not liable for delayed or undelivered
      Messages. Consumers may request up to a maximum of twenty (20) messages per month, with no more than three (3) text messages in one
      day.
    </p>
    <p className="p3">
      You understand that the text messages we send may be seen by anyone with access to your phone. Accordingly, you should take steps to
      safeguard your phone and your text messages if you want them to remain private.
    </p>
    <p className="p3">
      If you wish to stop receiving text messages from us, reply to any text message we have sent you and simply text &ldquo;STOP&rdquo;,
      &ldquo;END&rdquo; or &ldquo;QUIT&rdquo;. You may also request to stop receiving text messages by calling us or emailing us using the
      following information:
    </p>
    <p className="p5">
      <span className="s2">By email:&nbsp;</span>miraclesmileadvanced@rephelpdesk.com
    </p>
    <p className="p3">
      If at any time you need our contact information or information on how to stop text messages, reply to any text message we have sent
      you and simply text HELP or click here for support. Message and Data Rates May Apply to any text/SMS communication.
    </p>
    <p className="p2">
      <strong>SECTION 6 - LINKS</strong>
    </p>
    <p className="p3">
      Please note that this Privacy Policy does not apply to the practices of companies that we do not own or control or to people that we
      do not employ or manage. We provide these links merely for your convenience. We have no control over, do not review, and are not
      responsible for Third-Party Sites, their content, or any goods or services available through the Third-Party Sites. Our Privacy Policy
      does not apply to Third Party Sites, and any data you provide to Third-Party Sites, you provide at your own risk. We encourage you to
      review the privacy policies of any Third-Party Sites with which you interact with.
    </p>
    <p className="p2">
      <strong>SECTION 7 - SECURITY</strong>
    </p>
    <p className="p3">
      To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not
      inappropriately lost, misused, accessed, disclosed, altered or destroyed.
    </p>
    <p className="p3">
      If you provide us with sensitive information, such as login credentials and credit card information, then such information is
      encrypted using secure socket layer technology (SSL) and stored with an AES-256 encryption. Although no method of transmission over
      the Internet or electronic storage is 100% secure, we use generally acceptable commercially standards to protect your personal
      information.
    </p>
    <p className="p2">
      <strong>SECTION 8 &ndash; BREACH</strong>
    </p>
    <p className="p3">
      Unless otherwise prohibited by applicable law, we shall notify you, as soon as it is reasonably possible under the circumstances but
      in any event no later than within 72 hours after becoming aware, of any accidental, unauthorized, or unlawful destruction, loss,
      alteration, or disclosure of, or access to, Personal Data ("Security Breach") likely to result in a high risk of adversely affecting
      individuals&rsquo; rights and freedom. Such notification shall include: (a) a detailed description of the Security Breach, and (b) the
      type of data that was the subject of the Security Breach and we shall communicate (i) the name and contact details of our data
      protection officer or other point of contact where more information can be obtained; (ii) a description of the likely consequences of
      the Security Breach; (iii) a description of the measures taken or proposed to be taken by us to address the Security Breach,
      including, where appropriate, measures to mitigate its possible adverse effects; and (iv) additionally in such notification or
      thereafter as soon as such information can be collected or otherwise becomes available, any other information you may reasonably
      request relating to the Security Breach.
    </p>
    <p className="p3">
      We shall take prompt action to investigate the Security Breach and shall use industry standard, commercially reasonable, efforts to
      mitigate the effects of any such Security Breach in accordance with its obligations hereunder and, subject to your prior written
      agreement, to carry out any recovery or other action necessary to remedy the Security Breach. Unless required to do so under
      applicable Privacy Law, we shall not release or publish any filing, communication, notice, press release, or report concerning any
      Security Breach. We shall also report such Security Breach as may be required by law to relevant supervisory authority within 72 hours
      of becoming aware, where feasible.
    </p>
    <p className="p2">
      <strong>SECTION 9 &ndash; GEOGRAPHIC LOCATION</strong>
    </p>
    <p className="p3">
      Where personal data originating in the European Economic Area is processed outside the European Economic Area, in a territory that has
      not been designated by the European Commission as ensuring an adequate level of protection pursuant to applicable Privacy Law, we
      agree that the transfer shall be undertaken pursuant to SECTION 6 above, which we shall maintain in full force and effect.
    </p>
    <p className="p3">
      We have data processing agreements in place to ensure compliance with all relevant Directives. All processing is performed in
      accordance with the highest security regulations.
    </p>
    <p className="p2">
      <strong>SECTION 10 - AGE OF CONSENT</strong>
    </p>
    <p className="p3">
      By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are
      the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to
      use this site.
    </p>
    <p className="p2">
      <strong>SECTION 11 - CHANGES TO THIS PRIVACY POLICY</strong>
    </p>
    <p className="p3">
      We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take
      effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has
      been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use or
      disclose it. Continued use of our Site after having been informed of any such changes to these conditions implies acceptance of the
      revised Privacy Policy. This Privacy Policy is an integral part of our Terms of Service.
    </p>
    <p className="p3">
      If our Company is acquired or merged with another company, we may disclose your Personal Data with our prospective or actual
      purchasers, investors, or successor entities in connection with a contemplated reorganization or an actual reorganization of our
      business, in connection with financing, a sale, or other transaction involving the disposal of all or part of our business or assets,
      including for the purpose of permitting the due diligence required to decide whether to proceed with a transaction, pursuant to
      assurances of sufficient data handling practices and safeguards.
    </p>
    <p className="p3">
      Residents of the European Economic Area: Our disclosure is limited to situations where we are permitted to do so under applicable
      European and national data protection laws and regulations.
    </p>
    <p className="p2">
      <strong>QUESTIONS AND CONTACT INFORMATION</strong>
    </p>
    <p className="p3">
      If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply
      want more information, contact us at&nbsp;<span className="s1">miraclesmileadvanced@rephelpdesk.com</span>.
    </p>
    <p className="p3">
      We have a &ldquo;Data Protection Officer&rdquo; who is responsible for matters relating to privacy and data protection. This Data
      Protection Officer can be reached at the following address:
    </p>
    <p className="p3">Ontel Products Corporation, 21 Law Drive, Fairfield, NJ 07004</p>
  </div>
);

export default PrivacyPolicy;
