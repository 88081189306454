import tag from 'app/entities/tag/tag.reducer';
import productCategory from 'app/entities/product-category/product-category.reducer';
import product from 'app/entities/product/product.reducer';
import cart from 'app/entities/cart/cart.reducer';
import order from 'app/entities/order/order.reducer';
import payment from 'app/entities/payment/payment.reducer';
import productTag from 'app/entities/product-tag/product-tag.reducer';
import productInCart from 'app/entities/product-in-cart/product-in-cart.reducer';
import discountCode from 'app/entities/discount-code/discount-code.reducer';
import orderStatus from 'app/entities/order-status/order-status.reducer';
import paymentMethod from 'app/entities/payment-method/payment-method.reducer';
import address from 'app/entities/address/address.reducer';
import paymentGateway from 'app/entities/payment-gateway/payment-gateway.reducer';
import productAssert from './product-assert/product-assert.reducer';
import tax from 'app/modules/checkout/tax.reducer';
import checkout from 'app/modules/checkout/checkout.reducer';
import addProduct from 'app/override/modules/home-page/product.reducer';
import orderModify from 'app/entities/order/order-modify.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  tag,
  productCategory,
  product,
  cart,
  order,
  payment,
  productTag,
  productInCart,
  discountCode,
  orderStatus,
  paymentMethod,
  address,
  paymentGateway,
  productAssert,
  tax,
  checkout,
  addProduct,
  orderModify,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
