import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ICart } from 'app/shared/model/cart.model';
import { getEntities as getCarts } from 'app/entities/cart/cart.reducer';
import { IOrder } from 'app/shared/model/order.model';
import { OrderStatus } from 'app/shared/model/enumerations/order-status.model';
import { getEntity, updateEntity, createEntity, reset } from './order.reducer';

export const OrderUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const carts = useAppSelector(state => state.cart.entities);
  const orderEntity = useAppSelector(state => state.order.entity);
  const loading = useAppSelector(state => state.order.loading);
  const updating = useAppSelector(state => state.order.updating);
  const updateSuccess = useAppSelector(state => state.order.updateSuccess);
  const orderStatusValues = Object.keys(OrderStatus);

  const handleClose = () => {
    navigate('/order' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getCarts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...orderEntity,
      ...values,
      cart: carts.find(it => it.id === values.cart),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...orderEntity,
          cart: orderEntity?.cart?.id,
        };

  return (
    <div className="order-update">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="coreShoppingCartApp.order.home.createOrEditLabel" data-cy="OrderCreateUpdateHeading" className="fs-bold pb-5">
            Update a Order
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name={orderEntity.orderNumber ? 'orderNumber' : 'id'}
                  required
                  readOnly
                  id="order-id"
                  label={'Order Number'}
                  validate={{ required: true }}
                  disabled
                />
              ) : null}
              {/* <ValidatedField
                label={translate('coreShoppingCartApp.order.totalAmount')}
                id="order-totalAmount"
                name="totalAmount"
                data-cy="totalAmount"
                type="text"
              /> */}
              <ValidatedField
                label={translate('coreShoppingCartApp.order.status')}
                id="order-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {orderStatusValues.map(orderStatus => (
                  <option
                    value={orderStatus}
                    key={orderStatus}
                    disabled={orderStatus != 'CANCELLED' && orderStatus != 'CONFIRMED' && orderStatus != 'BACK_ORDERED'}
                  >
                    {/* {translate('coreShoppingCartApp.OrderStatus.' + orderStatus)} */}
                    {orderStatus}
                  </option>
                ))}
              </ValidatedField>
              {/* <ValidatedField
                label={translate('coreShoppingCartApp.order.taxAmount')}
                id="order-taxAmount"
                name="taxAmount"
                data-cy="taxAmount"
                type="text"
              /> */}
              {/* <ValidatedField id="order-cart" name="cart" data-cy="cart" label={translate('coreShoppingCartApp.order.cart')} type="select">
                <option value="" key="0" />
                {carts
                  ? carts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField> */}
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/order" replace color="info" className="mt-3">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating} className="mt-3">
                <FontAwesomeIcon icon="save" />
                &nbsp; Update
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderUpdate;
