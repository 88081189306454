import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity, updateEntity } from './order.reducer';

export const OrderCancelDialog = ({ orderId, showCancelModal }) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams<'id'>();
  id = orderId;

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const orderEntity = useAppSelector(state => state.order.entity);
  const updateSuccess = useAppSelector(state => state.order.updateSuccess);

  const handleClose = () => {
    showCancelModal(loadModal);
  };

  const confirmCancel = async () => {
    const orderCancelDetails = { ...orderEntity, status: 'CANCELLED' };
    await dispatch(updateEntity(orderCancelDetails));
    showCancelModal(loadModal);
  };

  return (
    <Modal isOpen centered>
      <ModalBody>Are you sure you want to cancel Order #{id}</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          &nbsp; No
        </Button>
        <Button id="jhi-confirm-delete-order" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmCancel}>
          &nbsp; Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default OrderCancelDialog;
